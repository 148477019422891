.left-space {
    padding-left: 10%;
}

ul {
    list-style-type: none;
}

.logo {
    width: 140px;
    display: block;
}

header {
    padding: 30px 0;
    display: flex;
    justify-content: space-between;
}

.home-page{
    color: #3D3D3D;
}

.welcome-heading{
    font-weight: bold;
    font-size: 40px;
}

.welcome-subheading {
    font-weight: 300;
    font-size: 24px;
    margin-bottom: 40px;
}

.banner-img {
    background-image: url(../../images/ais-images/banner.png);
    background-repeat: no-repeat;
    background-position: right;
    padding-bottom: 60px;
}
.content-wrap {
    width: 75%;
    background: linear-gradient(90.1deg, #FFFFFF 83.88%, rgba(255, 255, 255, 0) 99.9%);
}
.request-list{
    padding: 0px;
    flex-wrap: wrap;
}
.request-list li{
    width: 25%;
    padding: 5px;
}
.request-list .btn {
    white-space: break-spaces;
} 

.request-card {
    min-width: 165px;
    color: #3D3D3D;
    font-size: 17px;
    padding: 25px 10px;
    border: 1px solid #E7E9EA;
    border-radius: 20px;
    text-align: center;
    cursor: pointer;
    height: 100%;
}
.request-card.active{
    border: 2px solid #BFD22B;
    box-shadow: 1px 1px 8px #BFD22B;
}
.request-card:hover{
    box-shadow: 1px 1px 8px #BFD22B;
}

.request-card p{
    margin-bottom: 20px;
} 
.request-icon {
    margin-bottom: 10px;
    height: 94px;
}

.btn-darkgreen {
    background: #6EA32D;
    border-radius: 50px;
    color: white;
}

.btn-darkgreen:hover {
    background: #6EA32D;
    border-radius: 50px;
    color: white;
}

.howwork-section{
    padding: 60px 150px;
}

.howwork-heading{
    font-size: 36px;
    margin-bottom: 30px;
}

.howwork-subheading{
    font-weight: 300;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 50px;
}

.howwork-list {
    flex-wrap: wrap;
}

.howwork-list li{
    padding: 0 50px;
    width: 50%;
}

.howwork-list li ul li{
    padding: 0;
    width: 100%;
}

.howwork-content{
    /* margin-left: 20px; */
}

.howwork-content > h5{
    margin-top: 0px;
    margin-bottom: 6px;
}

.req-heading {
    font-weight: bold;
    font-size: 20px;
    line-height: 30px;
    color: #6EA32D;
    position: relative;
}

footer {
    background: #BFD22B;
    padding: 30px;
}

.footer-link-list {
    justify-content: center;
    flex-wrap: wrap;
}

.footer-link-list li{
    padding: 0 10px;
    border-left: 1px solid white;
}
.footer-link-list li:first-child{
    border-left: none;
}

.footer-link {
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    color: white;
}
.lang-list{
    justify-content: flex-end;
    padding: 10px 30px 10px 10px;
}
.lang-list li:last-child a{
    border-left: 1px solid  #547C22;
    padding-left: 10px;
    margin-left: 10px;
}
.lang-list a {
    font-size: 14px;
    line-height: 16px;
    color: #547C22;
}
.lang-list a.active {
    font-weight: bold;
}

@media(max-width: 991px) {
    .home-page{
        padding: 0 10px;
    }
    .left-space {
        padding: 0;
    }
    .content-wrap {
        width: 100%;
    }
    .request-list li{
        width: 50%;
    }
    .howwork-section {
        padding: 20px 10px;
    }
    .howwork-list li {
        width: 100%;
        padding: 0;
    }
    .content-wrap {
        background: none;
    }
    .banner-img{
        background-position: top right;
        background-image: url(../../images/ais-images/banner-xs.jpg);

    }
    .logo {
        width: 60px;
    }

    .welcome-heading{
        font-size: 22px;
        width: 156px;
        margin: 10px 0 10px;
    }
    
    .welcome-subheading {
        font-size: 14px;
    }
    header {
        justify-content: flex-end;
    }

   

    .request-list{
        padding: 0px 12px;
    }
    
}

@media (max-width: 767px) {
    .footer-link-list {
      display: block;
    }
    .footer-link-list li{
      border: none;
    }
    .request-card p {
        margin-bottom: 16px;
    }
    
  }

.request-list > li:last-child > div {
    padding: 32px 10px;
}

.request-list > li:last-child > div > span{
    padding: 6px;
}

@media (min-width: 992px){
    .request-card{
        padding: 32px 24px;
    }
}

.new-footer-link-list {
    max-width: 720px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
}

.new-footer-link-list > div {
    width: 50%;
}

.new-footer-link-list > div:last-child > ul {
    margin-bottom: 0;
}

.new-footer-link-list li{
    padding: 0;
    display: block;
}

.new-footer-link {
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    color: white;
}

@media (max-width: 767px){
    .new-footer-link-list > div {
        width: 100%;
    }
    .new-footer-link-list {
        flex-direction: column;
    }
}

.d-flex > div:first-child.howwork-content {
    padding: 15px 0;
}

.new-seal {
    background-image: url(../../images/homepage/new-seal.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    display: inline-block;
    width: 50px;
    height: 50px;
    margin-left: 5px;
    text-align: center;
    color: white;
    font-weight: bold;
    font-size: 12px;
    line-height: 50px;
    position: absolute;
    top: -8px;
}