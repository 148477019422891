.ais_outer-frame{
  border-radius: 10px;
  background-color: #ffffff;
  border: solid 1px #e7e9ea; 
  margin-top: 20px;
  clear:both;
}


.ais_outer-frame::before{
    clear:both;
}

.ais_track-delivery{
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
}

.ais_tracker-delivery-header{
    margin-top: 10px;
    font-size: 13px;
    font-weight: 900;
    text-align: center;
    color: #1d3444;
}

.ais_tracker-delivery-header-separator{
   padding-top: 40px;
   padding-left: 5px;
   padding-right: 5px;
   text-align: center;
}
.ais_status-icon{
  width: 18px;
  height: 18px;
  display: block !important;
  margin-left: auto;
  margin-right: auto;
}

.ais_default-status-icon{
  width: 18px;
  height: 18px;
  display: block !important;
  margin-left: auto;
  margin-right: auto;
}

.ais_active-status-icon{
  width: 33px;
  height: 33px;
  display: block !important;
  margin-left: auto;
  margin-right: auto;
}

.ais_inline{    
    display: inline-block;
}

.ais_progress-line{
    width: 60px;
    height: 15px;
    margin-bottom: 100px;
}

.ais_default-status-text{
  font-size: 13px;
  font-weight: 500;
  text-align: center;  
  padding-top: 25px;
  color: #c0c2c4;
}

.ais_active-status-text{
    font-size: 13px;
    font-weight: 500;
    text-align: center;  
    padding-top: 25px;
    color: #1d3444;
}

.ais_show{
    display: block ;
    font-size: 9px;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #8589a1;
    padding-top: 10px; 
      
}

.ais_hide{
    display: none;
    font-size: 9px;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #8589a1;
    padding-top: 10px;
}

.ais_default-stage{
    height: 25px;    
}

.ais_selected-div {
    background: #f5f5f5
}

.shippedTrack{
  text-align: center;
  padding: 20px;
}