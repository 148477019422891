.ais_errorMsg{
    font-weight: normal;
    color: #ED1A3D;
    font-size: 12px;
    position: absolute;
    /* right: 160px; */
    left: 10px;
    width: 189px;
}

.ais_errorMsg.ais_CC{
    font-weight: normal;
    color: #ED1A3D;
    font-size: 12px;
    position: absolute;
    width: 189px;
    display: unset;
}

.ais_nameErrPosition{
    top: 100px;
}

.ais_nameErrPositionCC{
    top: 106px;
}

.ais_numErrPosition{
    top: 100px;
    /* float: right; */
    left: 290px;
}

.ais_numErrPositionCC{
    top: 106px;
    left: 290px;
}

.ais_expErrPosition{
    /* top: 196px; */
    top: 72%;
    left: 6%;
}

.ais_expErrPositionCC{
    /* top: 196px; */
    top: 61%;
}


.ais_cvcErrPosition{
    /* top: 196px;
    left:290px; */
    top: 72%;
    left: 62%;
    /* width:137px; */
}

.ais_cvcErrPositionCC{
    top: 61%;
    left: 45%;
}

.ais_alignCenter{
    text-align: left;
    margin-right: 116px;
    height: 0px;
}

.ais_errorBorder{
    border:1px solid #ED1A3D;
    margin-bottom: 10px;
}

.ais_cvvDisc{
    -webkit-text-security: disc;
}

#amex{
    position: absolute;
    right: 11px;
    top: 25px;
    padding:10px;
}

#amex-modal{
    position: absolute;
}

#visa{
    position: absolute;
    right: 11px;
    top: 25px;
    padding:10px;
}


#visa-modal{
    position: absolute;
}

#mastercard{
    position: absolute;
    right: 11px;
    top: 25px;
    padding: 10px;
}
#mastercard-moal{
    position: absolute;
}


.ais_btnSel{
    background-color: #bfd22b !important;
    color: #fff !important;
}