.subscription-content label{
    font-size: 12px;
    line-height: 14px;
    color: #8589A1;
}
.subscription-content strong{
    font-size: 15px;
    line-height: 18px;
    color: #3D3D3D;
    display: block;
    margin-bottom: 10px;
}
.subscription-list {
    padding: 0;
    text-align: left;
}
.subscription-content {
    margin-left: 10px;
}
.subscription-list li{
    margin-bottom: 10px;
    align-items: center;
}
.subscription-list i{
    width: 50px;
    height: 50px;
}