.ais_service-unavailable-header {
    height: 100vh;
    padding: 30px;
    background-color: #ffffff;
    background-image: url('../../images/ais-images/shape.png');
    background-repeat: no-repeat;    
    background-size: 100% ;
    width: 100%;
    overflow-x: hidden;
    background-position: bottom;
}

.ais_serviceUnavailablelogo {
    display: flex;
}

.ais_serviceUnavailablelogo img {
    width: 80px;
    text-align: center;
}

.ais-submit-button{

    width: 150px;
    height: 44px;
    font-size: 16px;
    font-weight: 900;
    color: #ffffff;
    border-radius: 25px;
    text-align: center;
    border: none;
    margin: 0px auto;
    background-color: #bfd22b;
    font-family: Helvetica-Bold !important;
}

.ais_serviceUnavailableContent{
    font-family: Avenir-Light;
    font-weight: 300;
}

.ais_serviceUnavailableContent .ais_errorCode{
    font-size: 36px;
    font-family: Helvetica-Bold;
}

.ais_serviceUnavailableContent .ais_errorMsg{
    font-size: 20px;
    color: #3d3d3d;
    position: static;
    width: 100%;
    font-family: Helvetica !important;
    margin-top: 20px;
}
.endpage-logo{
    width:100%;
}

.ais_serviceUnavailableMsg {
    color:  #3d3d3d;
    text-align: center;
    font-size: 35px;
    font-family: Helvetica-bold;
    margin: auto;
    width: auto;
    line-height: 1;
    font-weight: 300;
}

.ais_closeWindow {
    color: #ffffff;
    font-size: 14px;
    font-weight: 500;
    float: right;
    padding: 20px 20px 0px 0px;
    cursor: pointer;
}

@media only screen and (max-width: 767px){
   .ais_serviceUnavailablelogo {
       display: block;
   }

}
  