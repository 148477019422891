.term-condition-page.term-condition-new-page ol {
    counter-reset: item;
}

.term-condition-page.term-condition-new-page ol li {
    display: block;
    position: relative;
    padding-bottom: 1rem;
    line-height: 1.5;
    padding-left: 50px;
    font-weight: bold;
    font-size: 18px;
}

.term-condition-page.term-condition-new-page ol li:before {
    content: counters(item, ".") ".";
    counter-increment: item;
    position: absolute;
    top: 0;
    left: 0;
    font-weight: bold;
}
.term-condition-page.term-condition-new-page ol li li {
    font-weight: normal;
    font-size: 16px;
}
.term-condition-page.term-condition-new-page ol li li:before {
    content: counters(item, ".") " ";
  }

.term-condition-page.term-condition-new-page ol li > ol {
    margin-top: 1rem;
    padding-left: 0;
}