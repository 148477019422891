@media (max-width: 991px) {
  .ais_transition-panel {
    display: none;
  }

  .bottom-wrap {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    border-bottom: 1px solid #E7E9EA;
  }
  .ais-wrap {
    padding: 10px 10px;
  }
  .bottom-wrap {
    padding: 10px 20px;
  }

  .ais_app-header {
    padding: 0;
    display: block;
    height: auto;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 100;
    background: #fff;
  }

  #spacer {
    height: 30px;
  }
  .ais-progressbar-div {
    margin-top: 14px;
    margin-bottom: 20px;
  }

  .ais_progress-line{
    width: 60px;
    height: 15px;
    margin-bottom: 50px;
}

  .ais_progress-bar-wrapper {
    width: unset;
    padding-bottom: 8px;
  }

  .ais_device-outer.ais_web {
    width: 300px;
  }
  .ais_user-input-div {
    width: 98% !important;
  }
  .ais_user-input-textbox-div {
    width: 96% !important;
  }

  .ais_app-header-text-cus {
    font-size: 18px;
  }

  .ais_app-header-text {
    width: 50%;
    float: left;
    margin: 0px 0px 21px 15px;
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #3d3d3d;
    font-family: DBHelvethaicaAISX-75Bd;
  }
  .ais_app-header .ais_logo {
    margin-left: 10px;
  }

  .ais_aheaderpp- .ais_close-button-wrapper .ais_close-btn {
    height: 15px;
  }

  .ais_app-header-sr {
    font-size: 14px;
  }

  /*
  ** Transition panel for mobile layout only.ais_
  ** Will be hidden for web view
  */
  .ais_device-progress-div {
    overflow: hidden;
    margin-top: 4px;
  }
  .ais_device-percentage-div {
    float: left;
    /* width: 15%; */
  }
  .ais_device-stage-progress-div {
    float: right;
    /* width: 87%; */
  }
  .ais_device-progress-bar-container {
    width: 230px;
    height: 7px;
    border-radius: 5.5px;
    background-color: #f8e3dc;
  }
  .ais_device-progress-bar {
    height: 7px;
    border-radius: 5.5px;
    transition: width 0.5s;
    background-color: #ff5114;
    width: 40%;
  }
  .ais_stage-name {
    font-family: SamsungOne-700;
    margin-left: 0px;
    color: #ff5114;
  }

  .ais_mobile-sr-time {
    display: block;
    margin: 4px;
  }

  .ais_current-form-device-div.ais_enquiryBox-selected {
    width: 100%;
    height: 100%;
    margin: initial;
  }
  /*END CSS CHANGES BY RASHMI*/

  /*chat input changes*/
  .ais_user-input-textbox-div {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 10;
    background-color: #ffffff;
    border-radius: 22px;
    width: 92%;
    height: 40px;

/*     margin-bottom: 2px;
    margin-left: 18px; */

    margin-bottom: 11px;
    margin-left: 8px;
    color: black;
  }
  .ais_user-input-textbox {
    border: 1px solid #cccccc;
    border-right: 0px;
    border-bottom-left-radius: 22px;
    border-top-left-radius: 22px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    padding: 10px;
    width: 84%;
    height: 44px;
    font-size: 17px;
    letter-spacing: normal;
    float: left;
  }
  .ais_user-input-img-wrapper {
    width: 15%;
    float: left;
    border: 1px solid #cccccc;
    border-left: 0px;
    border-top-right-radius: 22px;
    border-bottom-right-radius: 22px;
  }
  .ais_default-send-icon {
    margin-right: 7px;
  }
/*   .ais_activated-send-icon {
    margin: 28%;
    margin-top: 0px;
    margin-bottom: 0px;
    width: 24px;
    height: 24px;
  } */
  .ais_default-send-icon {
    background: url("./../images/ais-images/button-send-normal.png") no-repeat;
    height: 24px;
    width: 24px;
    float: left;
  }
  .ais_activated-send-icon {
    background: url("./../images/ais-images/button-send-active.png") no-repeat;
    width: 24px;
    height: 24px;
  }

  .ais_text-color {
    color: #3d3d3d;
  }

  .ais_placeholder-color {
    color: #8589a1;
  }
  /*end chat input changes*/

  /*header fix for iphonex*/
  .ais_header-wrapper{
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 1060;
    top: 0;
  }
}

@media (max-width: 767px) {

}

@media (min-width: 320px) and (max-width: 374px) {
  .ais_deliver-address-input.ais_mis{
    padding-left: 2px !important;
    padding-right: 2px !important;
    font-size: 14px !important;
  }
  .ais_deliver-address-input.ais_mis::placeholder{
    font-size: 12px;
  }
  /* .ais_app-message-window {
        height: 530px;
    } */
  /*Start of Header CSS*/
  /* .ais_app-header {
        height: 50px;
        background-image: linear-gradient(to right, #e32490, #ed1a3d);
        box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.ais_2);
    } */
  .ais_app-header-text {
    width: 80%;
    float: left;
    margin: 5px 0px 0px 0px;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    color: #ffffff;
    font-family: Avenir-Medium;
  }

  .ais_row-adress {
    height: 35px;
  }

  .ais_align-popup-button-resume {
    width: 45%;
    padding: 13px;
    border: solid 1px #e7e9ea;
    border-radius: 24px;
    display: inline-block;
    background-color: #e7e9ea;
    color: #1d3444;
    font-size: 12px;
  }

  .ais_align-popup-button-selected-resume {
    width: 45%;
    padding: 13px;
    border: solid 1px #e7e9ea;
    border-radius: 24px;
    display: inline-block;
    background-color: #bfd22b;
    color: white;
    font-size: 12px;
  }


  .ais_marginTop {
    margin-top: 15px;
  }

  .ais_inline {
    display:inline;
  }

  .ais_confirm-details-button {
    width: 90%;
    padding: 13px;
    margin-top: 15px;
    border: solid 1px #e7e9ea;
    border-radius: 24px;
    margin-bottom: 20px;
    background-color: #e7e9ea;
    color: #1d3444;
    font-size: 14px;
    margin-left:15px;
  }
  .ais_app-header-progress {
    font-size: 32px !important;
    margin-bottom: 10px;
    float: left;
    color: #ffffff;
  }

  .ais_app-header-progress > sup {
    font-size: 50%;
    top: -0.7em;
  }

  .ais_progressBarContainer {
    width: 238px;
    height: 5px;
    background-color: #fff;
    float: left;
    top: 32px;
    position: absolute;
    left: 73px;
    border-radius: 5.5px;
  }
  .ais_progressBar {
    height: 5px;
    background-color: #ebe013;
    width: 40%;
  }

  .ais_stageName {
    font-size: 13px;
    position: relative;
    left: 33px;
    top: 8px;
  }

  .ais_endChat {
    background-image: url("../images/mobile/ic-close@2x.png");
    background-repeat: no-repeat;
    background-size: cover;
    float: right;
    margin-top: 9px;
    margin-right: -6px;
    width: 14px;
    height: 14px;
  }
  .ais_current-mobile-device-label-header {
    /* font-family: Helvetica-Bold; */
    font-size: 15px;
    color: #1d3444;
    text-align: center;
    margin-bottom: 5px;
    margin-left:30px;
    /* line-height: 2; */
    font-weight: 700;
  }

  .ais_confirm-details-blue-label-custum {
    /* color: #abd8dc; */
    color: #6ea32d;
    font-size: 12px;
    font-family: Helvetica-Bold;
    display: inline-block;
    width: 50%;
    text-align: center;
  }

  .ais_image-icon-div {
    padding-left: 34px;
    padding-right: 40px;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .ais_confirm-details-dark-blue-label-custum {
    /*  font-family: Helvetica-Bold; */
     font-weight: bold;
     color: #3d3d3d;
     font-size: 13px;
     display: inline-block;
     width: 50%;
     padding-left:10px;
   }

   .ais_align-popup-button-alt {
    width: 60%;
    padding: 13px;
    border: solid 1px #e7e9ea;
    border-radius: 24px;
    display: inline-block;
    background-color: #e7e9ea;
    color: #1d3444;
    font-size: 14px;
    margin-bottom: 10px
  }

  .ais_align-popup-button-selected-alt {
    width: 60%;
    padding: 13px;
    border: solid 1px #e7e9ea;
    border-radius: 24px;
    display: inline-block;
    background-color: #bfd22b;
    color: white;
    font-size: 14px;
    margin-bottom: 10px
  }
  /*End Of Header CSS*/
  /*Start of CSS for Current time Displayed on Top*/
  /* .ais_currentTime{
        text-align: center;
        width: 132px;
        margin-top: 10px;
        margin-left: auto;
        margin-right: auto;
        font-family: Avenir-Book;
        font-size: 10px;
        color:#1d3444;
    } */
  /*End of CSS for Current time Displayed on Top*/

  /*Start of CSS for Chat Board where chats will be displayed*/

  /* .ais_chatBoard{
        height: calc(100% - 88px);
        overflow-y: auto;
        margin-left: auto;
        margin-right: auto;
        width: 100%;;
     }  */
  .ais_sent {
    margin: 0px 10px 10px 15vh;
  }
  /*End of CSS for Chat Board where chats will be displayed*/
  /*Start of CSS for input Box for Chatbot*/
  .ais_inputChatBot {
    height: 46px;
    width: 100%;
    position: fixed;
    bottom: 0px;
    padding: 5px;
    border-radius: 4px;
    background-color: #f5f5f5;
  }

  .ais_attachment {
    margin: 2px 8px 0px 3px;
    float: left;
  }

  .ais_attachment > img {
    height: 30px;
    width: 30px;
  }

  .ais_inputForBot {
    width: 218px;
    float: left;
    /* border:solid 1px red; */
  }

  .ais_footerInput {
    height: 41px;
    width: 222px;
    background-color: #f5f5f5;
    border: solid 1px #f5f5f5;
    font-family: Avenir-Book;
    font-size: 14px;
    color: #8589a1;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    padding: 2px;
  }

  .ais_sendButton {
    margin: 7px 8px 0px 3px;
    float: right;
  }

  .ais_sendButton > img {
    height: 30px;
    width: 30px;
  }
  /*End of CSS for input Box for Chatbot*/

  /*START - MESSAGE*/

  .ais_msg-time {
    font-size: 10px;
    text-align: left;
  }
  .ais_message-content {
    text-align: left;
    font-size: 14px;
    padding: 13px;
  }
  .ais_message-blk {
    float: none;
  }

  .ais_info-container {
    margin: 0px;
    width: 100%;
  }

  .ais_options-label {
    font-size: 14px;
  }

  .ais_options-div {
    height: 40px;
    width: 290px;
  }

  .ais_options-selected-div {
    height: 40px;
    width: 290px;
  }

  .ais_options-replacement-div {
    height: 40px;
    width: 280px;
  }

  .ais_options-replacement-selected-div {
    height: 40px;
    width: 280px;
  }

  .ais_options-selected-label {
    font-size: 14px;
  }

  .ais_sent-message {
    margin: 0px;
  }

  .ais_info-icon {
    margin-left: 10px;
  }

  .ais_sent-msg-time {
    font-size: 10px;
    padding-top: 12px;
  }
  /*END - MESSAGE*/

  /*START - CHAT ASSISTANCE */
  .ais_get-help {
    left: 40%;
  }
  /*END - CHAT ASSISTANCE */

  /* START - Feedback */
  .ais_question-label {
    font-size: 14px;
  }

  .ais_feedback-reason {
    height: 60px;
  }

  .ais_first-question {
    margin-top: 10px;
  }
  /*
    .ais_feedback-parent {
        margin-left: 0%;
        width: 100%
    } */

  .ais_slider-div {
    padding-top: 40px;
    width: 100% !important;
    left: 0%;
  }

  .ais_nps-span {
    font-size: 15px;
  }

  .ais_feedback-reason {
    font-size: 13px;
  }

  .ais_face-img {
    margin: 10px;
  }

  .ais_finish-div {
    left: 0%;
    width: calc(100% - 20px);
  }
  /* END - Feedback */

  .ais_user-input-div {
    background-color: #f5f5f5;
    /* overflow: auto; */
    width: 311px;
    bottom: 0;
    position: fixed;
    /* margin-right: 35px; */
    margin-left: 7px;
    height: 44px;
    margin-bottom: 2px;
  }

  .ais_enquiry-input-margin {
    margin-left: 18px;
  }

  .ais_user-input-textbox-div {
    width: 311px;
    float: left;
  }

/*   .ais_user-input-textbox {
    background-color: #f5f5f5;
    border: none;
    padding: 10px;
    width: 100%;
    height: 44px;
    font-family: Avenir-Book;
    font-size: 17px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
  } */

/*   .ais_text-color {
    color: #1d3444;
  }

  .ais_placeholder-color {
    color: #8589a1;
  } */

/*   .ais_default-send-icon {
    background: url("./../images/icons/ic-send_default.png") no-repeat;
    height: 24px;
    width: 24px;
    float: right;
    position: absolute;
    left: 271px;
  }

  .ais_activated-send-icon {
    background: url("./../images/icons/ic-send_active.png") no-repeat;
    height: 24px;
    width: 24px;
    float: right;
    position: absolute;
    left: 271px;
  } */
}

/* Media Query for iPhone 6/7/8 */
@media (min-width: 375px) and (max-width: 413px) {
  /* .ais_app-message-window {
        height: 634px;
    } */
  /*Start of Header CSS*/
  /* .ais_app-header {
        height: 50px;
        background-image: linear-gradient(to right, #e32490, #ed1a3d);
        box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.ais_2);
    } */
  .ais_sent {
    margin: 0px 10px 10px 15vh;
  }
  .ais_current-mobile-device-content {
    font-size: 13px;
    color:black  !important;
    font-family: Helvetica;
    text-align: center;
    font-weight: 900;
    padding: 10px 0 10px 0;
  }

  .ais_align_centr{
    text-align: center;
    /* margin-left: 50px; */
  }

  .ais_row-adress {
    height: 35px;
  }
  .ais_align_rgt{
    text-align: right;
  }
  .ais_align_left{
    text-align: left;
  }
  .ais_center{
    width:40%;
    text-align: left !important;
    display:inline-table;
  }


  .ais_confirm-details-blue-label-custum {
    /* color: #abd8dc; */
    color: #6ea32d;
    font-size: 12px;
    font-family: Helvetica-Bold;
    display: inline-block;
    width: 50%;
    text-align: center;
  }

  .ais_image-icon-div {
    padding-left: 34px;
    padding-right: 40px;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .ais_confirm-details-dark-blue-label-custum {
    /*  font-family: Helvetica-Bold; */
     font-weight: bold;
     color: #3d3d3d;
     font-size: 13px;
     display: inline-block;
     width: 50%;
     padding-left:10px;
   }

   .ais_align-popup-button-alt {
    width: 60%;
    padding: 13px;
    border: solid 1px #e7e9ea;
    border-radius: 24px;
    display: inline-block;
    background-color: #e7e9ea;
    color: #1d3444;
    font-size: 14px;
    margin-bottom: 10px
  }

  .ais_align-popup-button-selected-alt {
    width: 60%;
    padding: 13px;
    border: solid 1px #e7e9ea;
    border-radius: 24px;
    display: inline-block;
    background-color: #bfd22b;
    color: white;
    font-size: 14px;
    margin-bottom: 10px
  }

  .ais_align-popup-button-resume {
    width: 45%;
    padding: 13px;
    border: solid 1px #e7e9ea;
    border-radius: 24px;
    display: inline-block;
    background-color: #e7e9ea;
    color: #1d3444;
    font-size: 12px;
  }

  .ais_align-popup-button-selected-resume {
    width: 45%;
    padding: 13px;
    border: solid 1px #e7e9ea;
    border-radius: 24px;
    display: inline-block;
    background-color: #bfd22b;
    color: white;
    font-size: 12px;
  }


  .ais_confirm-details-button {
    width: 90%;
    padding: 13px;
    margin-top: 15px;
    border: solid 1px #e7e9ea;
    border-radius: 24px;
    margin-bottom: 20px;
    background-color: #e7e9ea;
    color: #1d3444;
    font-size: 14px;
    margin-left:15px;
  }
  .ais_deliver-address-input.ais_mis{
    padding-left: 2px !important;
    padding-right: 2px !important;
    font-size: 14px !important;
  }


  .ais_current-terms-div-cus {
    border: solid 1px #e7e9ea;
    border-radius: 16px 16px 16px 16px;
    width: 320px;
    overflow: auto;
    background-color: #ffffff;
    margin: auto;
  }

  .ais_device-select-button.ais_credit-card {
    margin-bottom: 0px;
    width: 100%;
  }

  .ais_device-select-button.ais_credit-cardd {
    margin-bottom: 0px;
    width: 45% !important;
    font-size: 13px !important;
  }


  .ais_confirm-delivery-icn.ais_emi {
    display: inline-block;
    width: 30%;
  }

  .ais_custom-border{
    padding-top: 10px;
    padding-bottom: 9px;
    border-bottom: 1px solid rgba(134, 134, 134, 0.5);
    background: white;
   }

   .ais_inline {
    display:inline;
  }

  .ais_current-form-emi-div {
    width: 60%;
    float: right;
  }
  .ais_app-header-text {
    width: 80%;
    float: left;
    margin: 5px 0px 0px 0px;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    color: #ffffff;
    font-family: Avenir-Medium;
  }
  .ais_deliver-address-input.ais_emi {
    width: 48% !important;
    display: inline-block;
  }
  .ais_device-select-button.ais_emi {
    width: 48% !important;
    display: inline-block;
  }

  .ais_device-divider.ais_emi {
    width: 3%;
    height: auto;
    display: inline-block;
  }

  .ais_marginTop {
    margin-top: 15px;
  }

  .ais_app-header-progress {
    font-size: 32px !important;
    margin-bottom: 10px;
    float: left;
    color: #ffffff;
  }

  .ais_app-header-progress > sup {
    font-size: 50%;
    top: -0.7em;
  }

  /* this will be visible only for mobile layouts */
  .ais_mobile-sr-time {
    display: inline-block;
    width: 100%;
    margin: 14px;
  }

  .ais_progressBarContainer {
    width: 292px;
    height: 5px;
    background-color: #fff;
    float: left;
    top: 32px;
    position: absolute;
    left: 73px;
    border-radius: 5.5px;
  }
  .ais_progressBar {
    height: 5px;
    background-color: #ebe013;
    width: 40%;
  }

  .ais_stageName {
    font-size: 13px;
    position: relative;
    left: 33px;
    top: 8px;
  }

  .ais_endChat {
    background-image: url("../images/mobile/ic-close@2x.png");
    background-repeat: no-repeat;
    background-size: cover;
    float: right;
    margin-top: 9px;
    margin-right: -6px;
    width: 14px;
    height: 14px;
  }

  .ais_current-mobile-device-label-header {
    /* font-family: Helvetica-Bold; */
    font-size: 15px;
    color: #1d3444;
    text-align: center;
    margin-bottom: 5px;
    margin-left:30px;
    /* line-height: 2; */
    font-weight: 700;
  }
  /*End Of Header CSS*/
  /*Start of CSS for Current time Displayed on Top*/
  /* .ais_currentTime{
        text-align: center;
        width: 132px;
        margin-top: 10px;
        margin-left: auto;
        margin-right: auto;
        font-family: Avenir-Book;
        font-size: 10px;
        color:#1d3444;
    } */
  /*End of CSS for Current time Displayed on Top*/

  /*Start of CSS for Chat Board where chats will be displayed*/
  /* .ais_chatBoard{
        height: calc(100% - 88px);
        overflow-y: auto;
        margin-left: auto;
        margin-right: auto;
        width: 100%;;
    } */
  /*End of CSS for Chat Board where chats will be displayed*/

  /*Start of CSS for input Box for Chatbot*/
  .ais_inputChatBot {
    height: 46px;
    width: 100%;
    position: fixed;
    bottom: 0px;
    padding: 5px;
    border-radius: 4px;
    background-color: #f5f5f5;
  }

  .ais_attachment {
    margin: 2px 8px 0px 3px;
    float: left;
  }

  .ais_attachment > img {
    height: 30px;
    width: 30px;
  }

  .ais_inputForBot {
    width: 266px;
    float: left;
    /* border:solid 1px red; */
  }

  .ais_footerInput {
    height: 41px;
    width: 266px;
    background-color: #f5f5f5;
    border: solid 1px #f5f5f5;
    font-family: Avenir-Book;
    font-size: 14px;
    color: #8589a1;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    padding: 2px;
  }

  .ais_sendButton {
    margin: 7px 8px 0px 3px;
    float: right;
  }

  .ais_sendButton > img {
    height: 30px;
    width: 30px;
  }
  /*End of CSS for input Box for Chatbot*/

  /*START - MESSAGE*/



  .ais_msg-time {
    font-size: 10px;
    text-align: left;
  }
  .ais_message-content {
    text-align: left;
    font-size: 14px;
    padding: 13px;
  }
  .ais_message-blk {
    float: none;
  }
  .ais_info-container {
    margin: 15px 30px auto;
    width: 85%;
  }

  .ais_options-label {
    font-size: 14px;
  }

  .ais_options-div {
    height: 40px;
    width: 290px;
  }

  .ais_options-selected-div {
    height: 40px;
    width: 290px;
  }

  .ais_options-replacement-div {
    height: 40px;
    width: 280px;
  }

  .ais_options-replacement-selected-div {
    height: 40px;
    width: 280px;
  }

  .ais_options-selected-label {
    font-size: 14px;
  }

  .ais_sent-message {
    margin: 0px;
  }

  .ais_sent-msg-time {
    font-size: 10px;
    padding-top: 12px;
  }
  /*END - MESSAGE*/
  /*START - CHAT ASSISTANCE */
  .ais_get-help {
    left: 40%;
  }
  /*END - CHAT ASSISTANCE */
  /* START - Feedback */
  .ais_question-label {
    font-size: 14px;
  }

  /* .ais_feedback-parent {
        margin-left: 0%;
        width: 100%
    } */

  .ais_slider-div {
    padding-top: 40px;
    width: 100% !important;
    left: 0%;
  }

  .ais_nps-span {
    font-size: 15px;
  }

  .ais_feedback-reason {
    font-size: 13px;
  }

  .ais_face-img {
    margin: 10px;
  }

  .ais_finish-div {
    left: 0%;
    width: calc(100% - 20px);
  }
  /* END - Feedback */

  .ais_user-input-div {
    background-color: #f5f5f5;
    /* overflow: auto; */
    width: 362px;
    bottom: 0;
    position: fixed;
    /* margin-right: 35px; */
    margin-left: 7px;
    height: 44px;
    margin-bottom: 2px;
  }

  .ais_enquiry-input-margin {
    margin-left: 20px;
  }

  .ais_user-input-textbox-div {
    width: 351px;
    float: left;
  }

/*   .ais_user-input-textbox {
    background-color: #f5f5f5;
    border: none;
    padding: 10px;
    width: 100%;
    height: 44px;
    font-family: Avenir-Book;
    font-size: 17px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
  } */

/*   .ais_text-color {
    color: #1d3444;
  }

  .ais_placeholder-color {
    color: #8589a1;
  } */

/*   .ais_default-send-icon {
    background: url("./../images/icons/ic-send_default.png") no-repeat;
    height: 24px;
    width: 24px;
    float: right;
    position: absolute;
    left: 327px;
  }

  .ais_activated-send-icon {
    background: url("./../images/icons/ic-send_active.png") no-repeat;
    height: 24px;
    width: 24px;
    float: right;
    position: absolute;
    left: 327px;
  } */
}



@media (min-width: 414px) and (max-width: 518px) {
  /* .ais_app-message-window {
        height: 700px;
    } */
  /*Start of Header CSS*/
  /* .ais_app-header {
        height: 50px;
        background-image: linear-gradient(to right, #e32490, #ed1a3d);
        box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.ais_2);
    } */
  .ais_sent {
    margin: 0px 10px 10px 15vh;
  }
  .ais_app-header-text {
    width: 80%;
    float: left;
    margin: 5px 0px 0px 0px;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    color: #ffffff;
    font-family: Avenir-Medium;
  }

  .ais_row-adress {
    height: 35px;
  }
  .ais_confirm-details-button {
    width: 90%;
    padding: 13px;
    margin-top: 15px;
    border: solid 1px #e7e9ea;
    border-radius: 24px;
    margin-bottom: 20px;
    background-color: #e7e9ea;
    color: #1d3444;
    font-size: 14px;
    margin-left:15px;
  }

  .ais_deliver-address-input.ais_mis{
    padding-left: 2px !important;
    padding-right: 2px !important;
    font-size: 14px !important;
  }

  .ais_deliver-address-input.ais_mis::placeholder{
    font-size: 12px;
  }

  .ais_current-mobile-device-content {
    font-size: 13px;
    color:black !important;
    font-family: Helvetica;
    text-align: center;
    font-weight: 900;
    padding: 10px 0 10px 0;
  }

  .ais_current-terms-div-cus {
    border: solid 1px #e7e9ea;
    border-radius: 16px 16px 16px 16px;
    width: 370px;
    overflow: auto;
    background-color: #ffffff;
    margin: auto;
  }

  .ais_marginTop {
    margin-top: 15px;
  }

  .ais_app-header-progress {
    font-size: 32px !important;
    margin-bottom: 10px;
    float: left;
    color: #ffffff;
  }

  .ais_confirm-details-dark-label {
    color: var(--black);
    /* font-family: Helvetica-Bold; */
    font-size: 13px;
    /* display: inline-block; */
    width: 100%;
  }

  .ais_app-header-progress > sup {
    font-size: 50%;
    top: -0.7em;
  }

  .ais_progressBarContainer {
    width: 332px;
    height: 5px;
    background-color: #fff;
    float: left;
    top: 32px;
    position: absolute;
    left: 73px;
    border-radius: 5.5px;
  }
  .ais_progressBar {
    height: 5px;
    background-color: #ebe013;
    width: 40%;
  }

  .ais_stageName {
    font-size: 13px;
    position: relative;
    left: 33px;
    top: 8px;
  }

  .ais_endChat {
    background-image: url("../images/mobile/ic-close@2x.png");
    background-repeat: no-repeat;
    background-size: cover;
    float: right;
    margin-top: 9px;
    margin-right: -6px;
    width: 14px;
    height: 14px;
  }

  .ais_image-icon-div {
    padding-left: 34px;
    padding-right: 40px;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .ais_confirm-details-dark-blue-label-custum {
    /*  font-family: Helvetica-Bold; */
     font-weight: bold;
     color: #3d3d3d;
     font-size: 13px;
     display: inline-block;
     width: 50%;
     padding-left:10px;
   }

   .ais_align-popup-button-alt {
    width: 60%;
    padding: 13px;
    border: solid 1px #e7e9ea;
    border-radius: 24px;
    display: inline-block;
    background-color: #e7e9ea;
    color: #1d3444;
    font-size: 14px;
    margin-bottom: 10px
  }

  .ais_align-popup-button-selected-alt {
    width: 60%;
    padding: 13px;
    border: solid 1px #e7e9ea;
    border-radius: 24px;
    display: inline-block;
    background-color: #bfd22b;
    color: white;
    font-size: 14px;
    margin-bottom: 10px
  }
  /*End Of Header CSS*/
  /*Start of CSS for Current time Displayed on Top*/
  /* .ais_currentTime{
        text-align: center;
        width: 132px;
        margin-top: 10px;
        margin-left: auto;
        margin-right: auto;
        font-family: Avenir-Book;
        font-size: 10px;
        color:#1d3444;
    } */
  /*End of CSS for Current time Displayed on Top*/

  /*Start of CSS for Chat Board where chats will be displayed*/
  /* .ais_chatBoard{
        height: calc(100% - 88px);
        overflow-y: auto;
        margin-left: auto;
        margin-right: auto;
        width: 100%;;

    } */
  /*End of CSS for Chat Board where chats will be displayed*/

  /*Start of CSS for input Box for Chatbot*/
  .ais_inputChatBot {
    height: 52px;
    width: 100%;
    position: fixed;
    bottom: 0px;
    padding: 5px;
    border-radius: 4px;
    background-color: #f5f5f5;
  }

  .ais_attachment {
    margin: 2px 8px 0px 3px;
    float: left;
  }

  .ais_attachment > img {
    height: 30px;
    width: 30px;
  }

  .ais_inputForBot {
    width: 266px;
    float: left;
    /* border:solid 1px red; */
  }

  .ais_footerInput {
    height: 41px;
    width: 266px;
    background-color: #f5f5f5;
    border: solid 1px #f5f5f5;
    font-family: Avenir-Book;
    font-size: 14px;
    color: #8589a1;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    padding: 2px;
  }

  .ais_sendButton {
    margin: 7px 8px 0px 3px;
    float: right;
  }

  .ais_sendButton > img {
    height: 30px;
    width: 30px;
  }
  /*End of CSS for input Box for Chatbot*/

  /*START - MESSAGE*/


  .ais_msg-time {
    font-size: 10px;
    text-align: left;
  }

  .ais_message-content {
    text-align: left;
    font-size: 14px;
    padding: 13px;
  }

  .ais_message-blk {
    float: none;
  }

  .ais_info-container {
    margin: 15px 30px auto;
    width: 85%;
  }

  .ais_options-label {
    font-size: 14px;
  }

  .ais_options-div {
    height: 40px;
    width: 290px;
  }

  .ais_options-selected-div {
    height: 40px;
    width: 290px;
  }

  .ais_options-replacement-selected-div {
    height: 32px;
    width: 280px;
  }

  .ais_options-replacement-div {
    height: 32px;
    width: 280px;
  }

  .ais_options-selected-label {
    font-size: 14px;
  }

  .ais_sent-message {
    margin: 0px;
  }

  .ais_sent-msg-time {
    font-size: 10px;
    padding-top: 12px;
  }
  /*END - MESSAGE*/
  /*START - CHAT ASSISTANCE */
  .ais_get-help {
    left: 40%;
  }
  /*END - CHAT ASSISTANCE */

  /* START - Feedback */
  .ais_question-label {
    font-size: 14px;
  }
  /*
    .ais_feedback-parent {
        margin-left: 0%;
        width: 100%
    } */

  .ais_slider-div {
    padding-top: 40px;
    width: 100% !important;
    left: 0%;
  }

  .ais_nps-span {
    font-size: 15px;
  }

  .ais_feedback-reason {
    font-size: 13px;
  }

  .ais_face-img {
    margin: 10px;
  }

  .ais_finish-div {
    left: 0%;
    width: calc(100% - 20px);
  }
  /* END - Feedback */
  .ais_user-input-div {
    background-color: #f5f5f5;
    /* overflow: auto; */
    width: 404px;
    bottom: 0;
    position: fixed;
    /* margin-right: 35px; */
    margin-left: 7px;
    height: 44px;
    margin-bottom: 2px;
  }

  .ais_enquiry-input-margin {
    margin-left: 20px;
  }

  .ais_user-input-textbox-div {
    width: 404px;
    float: left;
  }

/*   .ais_user-input-textbox {
    background-color: #f5f5f5;
    border: none;
    padding: 10px;
    width: 100%;
    height: 44px;
    font-family: Avenir-Book;
    font-size: 17px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
  } */

/*   .ais_text-color {
    color: #1d3444;
  }

  .ais_placeholder-color {
    color: #8589a1;
  } */

/*   .ais_default-send-icon {
    background: url("./../images/icons/ic-send_default.png") no-repeat;
    height: 24px;
    width: 24px;
    float: right;
    position: absolute;
    left: 362px;
  }

  .ais_activated-send-icon {
    background: url("./../images/icons/ic-send_active.png") no-repeat;
    height: 24px;
    width: 24px;
    float: right;
    position: absolute;
    left: 362px;
  } */
}


.ais_showElement {
  display: block;
}

.ais_hideElement {
  display: none;
}


/* Add Animation */
@-webkit-keyframes slideIn {
  from {
    bottom: -300px;
    opacity: 0;
  }
  to {
    bottom: 0;
    opacity: 1;
  }
}
@keyframes slideIn {
  from {
    bottom: -300px;
    opacity: 0;
  }
  to {
    bottom: 0;
    opacity: 1;
  }
}
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Modal Content */
.ais_modal {
  display: block;
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  /* background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.5); /* Black w/ opacity */
  animation-name: fadeIn; /* Fade in the background */
  animation-duration: 1s; /* Fade in the background */
}
.ais_modal-content {
  position: fixed;
  background-color: #fefefe;
  width: 92%;
  animation-name: slideIn;
  animation-duration: 1.2s;
  border-radius: 16px 16px 0px 0px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border: none;
  top: auto;
  left: 0;
  right: 0;
  bottom: 0;
}
.ais_modal-CC-wrapper,
.ais_modal-wrapper {
  line-height: 45px;
  border: 1px solid #e7e9ea;
  border-radius: 6px 6px 0px 0px;
  background-color: white;
  margin: auto;
}
.ais_scroll-div-address {
  overflow: scroll;
  height: 60vh;
}
.ais_modal-wrapper::after {
  /* background: url("../images/samsung/images/img-address.png") no-repeat center; */
  background-size: 50%;
  background-color: #fff;
  content: "";
  width: 78px;
  height: 58px;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  border-radius: 78px 78px 0 0;
  border-left: 1px solid #e7e9ea;
  border-top: 1px solid #e7e9ea;
  border-right: 1px solid #e7e9ea;
  border-bottom: 3px solid white;
  top: -56px;
}


.ais_modal-CC-wrapper.ais_address::after {
  background: url("../images/ais-images/icon-delivery-address.png") no-repeat 14px
    14px !important;
    background-color: #fff !important;
}

.ais_modal-CC-wrapper::after {
  background: url("../images/ais-images/icon-payment-fee.png") no-repeat 16px
    14px;
  background-color: #fff;
  content: "";
  width: 77px;
  /* height: 72px; */
  height: 57px;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  border-radius: 78px 78px 0 0;
  border-left: 1px solid #e7e9ea;
  border-top: 1px solid #e7e9ea;
  border-right: 1px solid #e7e9ea;
  border-bottom: 3px solid white;
  top: -56px;
}
.ais_close-btn-add {
  position: absolute;
  vertical-align: top;
  top: 4px;
  right: 2%;
  width: 18px;
  height: 18px;
  margin: 8px 10px 0px 0px;
}
.ais_container-div-CC-modal,
.ais_container-div-address-modal {
  margin: 36px 20px 0px 20px;
  text-align: center;
  line-height: 1.5;
}
.ais_errorMsg-add {
  color: #ed1a3d;
  font-size: 12px;
  position: relative;
  display: block;
  margin-left: 10px;
  text-align: left;
  margin-bottom: 0px;
}
.ais_errorBorder {
  border: 1px solid #ed1a3d;
}

.ais_device-select-button.ais_credit-cardd {
  margin-bottom: 0px;
  width: 45% !important;
  font-size: 13px;
}

@media screen and (min-width: 991px) {
  .ais_modal-content{
    width: 72.5%;
    right: 25%;
  }
}