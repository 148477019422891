.ais_ajax-loader-wrapper {
  padding-top:20px;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  /* color: #8589a1; */
}

.ais_addLoaderDiv{
  position: fixed;
  bottom:8px;
  height: 65px;
  width: 100%;
}

.ais_ajax-loader-wrapper .ais_progress-bar-container{
  width: 500px;
  height: 5px;
  margin: 0 auto;
  border-radius: 5.5px;
  background-color: #e7e9ea;
}

.ais_ajax-loader-wrapper.ais_loading-completed{
  display:none;
}

@media(max-width: 767px) {
  .ais_ajax-loader-wrapper .ais_progress-bar-container{
    width: unset;
  }
}

.ais_ajax-loader-wrapper .ais_stage-progress-div {
  float: none;
  width: 100%;
  padding-top: 0px;
  margin-top: 0px;
  padding-bottom: 20px;
}


.ais_ajax-loader-wrapper .ais_progress-bar{
  height:5px;
  border-radius: 5.5px;
  background-image: linear-gradient(to right, #e32490, #ed1a3d);
  transition: width 0.5s;
}


.ais_ajax-loader-uploadwrapper {
  text-align: center;
  font-size: 10px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #8589a1;
}


.ais_ajax-loader-uploadwrapper .ais_progress-bar-container{
  height: 5px;
  margin: 0 auto;
  border-radius: 5.5px;
  background-color: #e7e9ea;
}

.ais_ajax-loader-uploadwrapper .ais_progress-bar-container{
  width: 100%;
}

.ais_ajax-loader-uploadwrapper .ais_stage-progress-div {
  float: none;
  width: 100%;
  padding-top: 0px;
  margin-top: 0px;
  padding-bottom: 20px;
}

.ais_ajax-loader-uploadwrapper .ais_progress-bar{
  height:5px;
  border-radius: 5.5px;
  background-image: linear-gradient(to right, #e32490, #ed1a3d);
}

@media (max-width: 768px) {
  .ais_addLoaderDiv {
    width: 90%;
  }
}