@font-face {
    font-family: "Helvetica";
    src: local("Helvetica"), url("../fonts/Helvetica.ttf") format("truetype");
    font-display: block;
  }
  
  @font-face {
    font-family: "Helvetica-Bold";
    src: local("Helvetica-Bold"),
      url("../fonts/Helvetica-Bold.ttf") format("truetype");
    font-display: block;
  }
  
  *:focus {
    outline: none;
  }
  .recaptcha-wrapper {
    width: 300px;
    height: 74px;
    margin: 20px auto 20px auto;
  }
  
  .captcha-wrapper {
    display: inline-block;
    vertical-align: top;
    margin: 10px 0px 5px 0px;
    text-align: center;
  }
  
  .captcha-content{
  text-align: center;
  }
  .inputCaptcha {
    font-size: 15px;
    color: #8589a1;
    border: solid 1px #e7e9ea;
    border-radius: 4px;
    background-color: #fff;
    padding: 9px 10px 8px 10px;
    width: 165px;
    vertical-align: inherit;
  }
  
  .desktopcaptchaImage {
    text-align: center;
    background-image: url(../images/common/captcha-bg-img.JPG);
    background-color: #e7e9ea;
    width: 130px;
    height: 41px;
    border-radius: 4px;
    border: 0;
    font-size: 24px;
    font-weight: 700;
    margin-left: 10px;
    vertical-align: inherit;
    display: inline-block;
  }
  
  .desktopRefreshIcon {
      width: 40px;
      height: 40px;
      background-image: url(../images/common/ic-refresh.png);
      background-repeat: no-repeat;
      background-size: contain;
      display: inline-block;
  }
  
  .ais_show-in-web {
    display: block;
    padding-left: 0px;
  }
  .ais_logo-wrapper img {
    width: auto; /* IE fix */
  }
  .ais_container-wrapper {
    width: 100vw;
    height: 100vh;
    margin: 0px auto;
  }
  .landing-page-wrapper {
    background-image: url(../images/ais-images/illustration.svg), url(../images/ais-images/login-logo@2x.png), url(../images/ais-images/landing-bg.svg);
    background-position: center, 50% 10%, top left;
    background-repeat: no-repeat;
    background-size: 55%, 15%, 100%;
  }
  .landing-mid-section {
    margin: 10px 0 0 90px;
    width: 365px;
  }
  .ais_header {
    width: auto;
    text-align: center;
  }
  .ais_header label {
    font-family: Helvetica-Bold;
    font-size: 22px;
    color: rgb(61, 61, 61);
    padding-top: 28px;
  }
  .ais_sub-header {
    text-align: left;
    margin-bottom: 20px;
  }
  .ais_sub-header label {
    font-family: Helvetica;
    font-size: 13px;
  }
  
  .ais_language-div {
    /* position: absolute; */
    /* right: 0; */
    text-align: right;
    margin: 60px 45px 0 0;
    color: #3d3d3d;
  }
  .ais_language-div a {
    color: #3d3d3d;
  }
  .ais_language-div a:hover,.ais_language-div a:focus {
    color: #547c22;
    font-weight: 700;
    text-decoration: none;
  }
  .ais_input-wrapper {
    text-align: center;
    margin-bottom: 5px;
  }
  .ais_input-name {
    font-family: Helvetica;
    font-size: 15px;
    border: solid 1px #e7e9ea;
    border-radius: 4px;
    background-color: #ffffff;
    padding: 10px 11px 11px 11px;
    width: 350px;
    height: 44px;
    border-radius: 22px;
    border: solid 1.5px #bfd22b;
  }
  .ais_disclaimer-wrapper {
    font-family: Helvetica;
    font-size: 11px;
    color: rgb(61, 61, 61);
    padding-top: 2px;
    /* width: 300px; */
    /* height: 120px; */
    /* margin: 0px auto; */
    line-height: 1.5;
  }
  #ais_disclaimer {
    margin-bottom: 28px;
    text-align: justify;
    height: 65px;
  }
  .ais_submit-button-wrapper {
    text-align: center;
  }
  .ais_submit-button-wrapper button {
    font-family: Helvetica;
    font-size: 16px;
    font-weight: 900;
    width: 350px;
    height: 44px;
    color: #ffffff;
    border-radius: 25px;
    text-align: center;
    border: none;
    outline: none;
    background-color: #bfd22b;
  }
  .errorBorder {
    border: none;
    outline: 0;
    box-shadow: 0 0 0 0.1rem rgba(237, 26, 61, 0.25);
  }
  .ais_input-name.errorBorder::placeholder {
    color: #ed1a3d;
  }
  .ais_footer {
    position: absolute;
    bottom: 0;
    left: 0;
    font-family: Helvetica;
    font-size: 12px;
    color: rgb(61, 61, 61);
    padding-bottom: 10px;
    width: 100%;
    text-align: center;
  }
  .ais_footer a {
    color: rgb(61, 61, 61);
  }
  .ais_greenish a {
    /* color: rgb(84, 124, 34); */
    color:#547c22;
    font-weight: 700;
  }
  
  .ais_web-display {
    display: inline;
  }
  
  #ais_copyright {
    margin-right: 30px;
  }
  
  @media (max-width: 768px) {
    .ais_container-wrapper .col-xs-12 {
      width: 100vw;
    }
    .landing-mid-section {
      width: auto;
      margin: auto;
    }
    .landing-page-wrapper {
      height: 350px;
      background-position: center 70%, 50% 5%, top left;
    }
    .ais_language-div {
      margin: 0px 10px 0 0;
    }
    .ais_submit-button-wrapper button, .ais_input-name {
      width: 300px;
    }
    .ais_footer {
      text-align: center;
    }
    .text-xs-center {
      text-align: center;
    }
    .inputCaptcha {
      width: 150px;
      padding: 9px 2px 8px 2px;
    }
    .desktopcaptchaImage {
      width: 105px;
     margin-left: 0px !important;
    }
    .desktopRefreshIcon {
      width: 35px;
    }
  }
  @media only screen and (max-device-width: 414px) {
    .ais_show-in-web {
      display: none;
      padding-left: 0px;
    }
    .ais_web-display {
      display: block;
    }
    .ais_language-div {
      margin-right: 12px;
    }
  }
  @media only screen and (device-width: 320px) {
    .ais_footer {
      position: static;
      bottom: 0;
      font-family: Helvetica;
      font-size: 12px;
      color: rgb(61, 61, 61);
      padding-bottom: 10px;
      padding-top: 10px;
    }
  }
  
   .headerText{
          font-family:Helvetica;
          font-size: 28px;
          font-weight: normal;
          margin:19px 771px 21px 95px;
          float: left;
      }
  
    .headerTermsText{
        font-family: Helvetica;
        font-size: 22px;
        font-weight: 100;
        font-style: normal;
        font-stretch: normal;
        margin:8px 30px;
        float: left;
        height: 50px;
      }
  
  
      .headerTermsTime{
          display:none;
          margin: 0px;
      }
  
      .headerTime{
          font-family: Helvetica;
          font-size: 14px;
          font-weight: normal;
          margin:29px 80px 21px 0px;
          float: right;
          display:block;
      }
  
    .acc-header{
          font-family:  Helvetica-Bold;
          font-size: 18px;
          font-weight: 900;
          text-align: center;
          color: #1d3444;
          width:100%;
      }  
      .headerTopSpace{
          margin-top: 4px;
      }
  
      .headings{
          color:#1d3444;
          font-weight: 900;
      }
  
      .headerTitle{
          color:#1d3444;
          font-weight: 900;
          margin: 35px 35px 10px 35px;
          padding: 5px;
      }
  
  
  
      .mobileSwop-logo{
          width: 9%;
          height: 8%;
      }
  
      #accordion .panel-body{
          padding:0px;
          text-align: justify;
      }
        .topSpace{
          margin-top: 20px;
      }
      #accordion .panel{
          border: none;
          border-radius: 3px;
          box-shadow: none;
          margin-bottom: 15px;
      }
      #accordion .panel-heading{
          padding: 0;
          border: none;
          border-radius: 3px;
      }
       
      #accordion .panel-title {
      text-align: left;
      font-family: Helvetica-bold;
      font-weight: 900;
      }
  
      #accordion .panel-title a{
          display: block;
          padding: 12px 15px;
          background: #fff;
          font-family: Helvetica;
          font-size: 17px;
          font-weight: 400;
          color: #6ea32d;
          /*border: 1px solid #ececec;*/
          box-shadow: 0 0 10px rgba(0,0,0,.05);
          position: relative;
          transition: all 0.5s ease 0s;
          box-shadow: 0 1px 2px rgba(43,59,93,0.30);
          text-decoration: none;
      }
      .headings{
          color:#1d3444;
      }
      #accordion .panel-title a.collapsed{
          box-shadow: none;
          color: #1d3444;
          box-shadow: 0 1px 2px rgba(43,59,93,0.30);
      }
      #accordion .panel-title a:before,
      #accordion .panel-title a.collapsed:before{
          content: "\f067";
          font-family: "Font Awesome 5 Free";
          width: 25px;
          height: 25px;
          line-height: 28px;
          font-size: 15px;
          font-weight: 900;
          color: #6ea32d;
          text-align: center;
          position: absolute;
          top: 8px;
          right: 15px;
          transform: rotate(135deg);
          transition: all 0.3s ease 0s;
      }
      #accordion .panel-title a.collapsed:before{
          color: #676767;
          transform: rotate(0);
      }
      #accordion .panel-title a:after{
          content: "";
          width: 1px;
          height: 100%;
          background: #ececec;
          position: absolute;
          top: 0;
          right: 55px;
      }
      #accordion .panel-body{
          padding: 15px;
          border: none;
          font-family: Helvetica;
          font-weight: normal;
          font-size: 15px;
          color: #1d3444;
          text-align: justify;
      }
  
  
  .panel-group ol {
      counter-reset: item;
         list-style-type: decimal;
         list-style-position: inside;
         text-indent: -25px;
         padding-left:25px;
  }
  .panel-group li {
      display: block
  }
   .panel-group li:before {
      content: counters(item, ".") " ";
      counter-increment: item
  }
  
  .eligibility {
   list-style-type: none;
  }
  
  .eligibility li {
     list-style-type: none;
  }


      .terms-conditions-table td, .terms-conditions-table th {
          padding: 10px;
          text-align: left;
          padding-left: 50px;
      }
      .terms-conditions-table table, .terms-conditions-table td, .terms-conditions-table th {
          border: 1px solid #000;
      }
  
      .terms-table {
          border-spacing: 0!important;
          border-collapse: collapse!important;
          padding: 0!important;
          margin: auto!important;
          width: 50% !important;
      }
  
      .terms-table th {
          background-color: #d3d3d3;
      }
      .terms-table td, .terms-table th {
          padding: 10px;
          text-align: center;
      }
      .terms-table table, .terms-table td, .terms-table th {
          border: 1px solid #000;
      }
  
.term-condition-page .panel-default>.panel-heading{
 background-color: transparent;
}