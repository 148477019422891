.getmore-text {
    font-weight: bold;
    font-size: 30px;
    color: #3D3D3D;
}
.getmore-options li{
    width: 180px;
    padding:10px;
}
.getmore-option p {
    font-size: 14px;
    margin-top: 10px;
}
.getmore-options {
    flex-wrap: wrap;
    justify-content: center;
    align-items: stretch;
    width: 100%;
}
.getmore-option {
    box-shadow: 4px 4px 20px rgba(80, 80, 80, 0.25);
    border-radius: 20px;
    cursor: pointer;
    padding: 20px 10px;
    height: 100%;
}
.getmore-options li.active .getmore-option{
    border: 2px solid #BFD22B;
    box-shadow: 1px 1px 8px #BFD22B;
}
.getmore-options li.active .getmore-option p {
    color: #6EA32D;
}
.getmore-option:hover{
    box-shadow: 1px 1px 8px #BFD22B;
}
.getmore-option.disabled{
    opacity: 0.6;
    pointer-events: none;
}
.getmore-options li.active path{
    fill: #6EA32D;
}

@media (max-width: 991px) {
    .getmore-options li{
        width: 50%;
    }
    .getmore-options {
        justify-content: left;
    } 
    .getmore-option p {
        height: auto;
    }
}

