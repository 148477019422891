.rangeslider__labels 
.rangeslider__label-item {
    color: #8589a1;
    top: -40px;
    font-size: 12px;
    border: none;
}

.rangeslider-horizontal .rangeslider__handle {
    width: 16px;
    height: 16px;
    border-radius: 8px;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    background-image: linear-gradient(to bottom, #bfd22b, #bfd22b);
    border: none;
    box-shadow: none;
    -webkit-box-shadow: none;
}

.rangeslider-horizontal .rangeslider__handle:after {
    display: none;
}

.rangeslider-horizontal .rangeslider__fill {
    height: 5px;
    border-radius: 8px;
    background-color: #e7e9ea;
    box-shadow: none;
    -webkit-box-shadow: none;
}

.rangeslider-horizontal { 
    height: 5px;
    border-radius: 8px;
    box-shadow: none;
    -webkit-box-shadow: none;
}