body{
    height:680px;
}

#indexBody{
    height: 100%;
}

.ais_awsappsync {
    height: 100vh;  /*96% */
}
.ais_endHeader {
    background-image: url('../../images/ais-images/people-mobile.png');
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    width: auto;
    overflow-x: hidden;
    background-position: bottom;
}

.ais_end-wrapper{
    text-align:center;
    margin: 0 auto;
}
.ais_endConvPagelogo {
   /*  display: block;
    position: relative;
    top: 23px;
    left: 10px; */
    text-align: center;
    font-size: 35px;
    font-family: helvetica-bold;
    margin-top: 135px;
    color: #3d3d3d;
}

.ais_endPageConvlogo>img {
    width: 140px;
    height: 71.ais_1px;
    text-align: center;
    /* margin-left: 593px; */
    margin-bottom: 10px;
}

.ais_endPagewelcomeMsg {
    color: #3d3d3d;
    text-align: center;
    font-size: 35px;
    font-family: Helvetica-Bold;
    margin: auto;
    height: 50px;
    width: auto;
    line-height: 1;
    font-weight: 300;
    margin-top: 50px;
}

.ais_closeDeskConversation {
    text-align: right;
    color: white;
    font-family: Avenir-Medium;
    font-size: 14px;
    padding-top: 20px;
    padding-right: 20px;
}

.ais_secondRow {
    text-align: center;
    position: absolute;
    bottom: -6px;
    left: 18px;
    overflow-y: hidden;
    width:100%;
}

.ais_secondRow img {
    /* width: 25%;
    height: 70vh; */
    width: 24%;
    height: 57vh;
}

.ais_device-divider.end {
    width: 2%;
    height: auto;
    display: inline-block;
}

.ais-submit-button-cus{

    width: 15%;
    font-size: 16px;
    font-weight: 900;
    color: #ffffff;
    border-radius: 25px;
    text-align: center;
    border: none;
    background-color: #bfd22b;
    padding: 10px;
}

.ais_end-logo-cus{
    margin-top: 30px;
    margin-left:20px;
    float: left;
}
