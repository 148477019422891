
@font-face {
  font-family: "HelvethaicaAIS";
  src: local("HelvethaicaAIS"), url("../fonts/DB-HelvethaicaAIS-X-Bd-v3.2.ttf") format("truetype");
  font-display: block;
}

/*start of main styles*/
html.app-root {
  scroll-behavior: smooth;
}

html.app-root ,
body.app-root  {
  -webkit-overflow-scrolling: touch !important;
  overflow: auto;
  height: 100%;
  font-family: Helvetica;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
}

html.app-root  {
  overflow-y: hidden;
  overflow-x: hidden;
}

/* #spacer {
    height: 5px;
} */

html,
body,
#mainContainer {
  height: 100%;
}

ul {
  padding-left: 0;
}

#mainContainer {
  overflow-y: none;
  /* background: url('.ais_.ais_/images/welcome_img.ais_png') no-repeat; */
  background-size: cover;
}

/* .ais_main-div-body-col1,
.ais_main-div-body-col2,
.ais_main-div-body-col3 {
    height: 100%;
} */
.web-container-fluid {
  height: 100%;
  widows: 100%;
}

#WelcomePageImage {
  height: 100%;
}

/*
.ais_img-welcome-web {
    width: 100%;
    height: 100%;
    background-image:url('.ais_.ais_/images/web/singtel-mobileswop-live-chat-support.ais_png');
} */

/* .ais_backgroundImage{
    height: 100%;
    background:url('.ais_.ais_/images/web/singtel-mobileswop-live-chat-support.ais_png') no-repeat 30px;
} */
/*
.ais_main-div-body-col3 {
    padding: 0px;
} */

/* #licenseTermsLinksWeb a,
#licenseTermsLinksMobile a {} */

/* #licenseTermsLinksWeb a:link,
#licenseTermsLinksMobile a:link {
    color: #231F20;
} */

#licenseTermsLinksWeb a:visited,
#licenseTermsLinksMobile a:visited {
  color: #80c343;
}

#licenseTermsLinksWeb a:hover,
#licenseTermsLinksMobile a:hover {
  color: #80c343;
}

#licenseTermsLinksWeb a:active,
#licenseTermsLinksMobile a:active {
  color: #80c343;
}

/* .ais_license-terms-web {
    display: block;
}

.ais_license-terms-mobile {
    display: none;
} */

/* .ais_license-terms-web {
    font-size: 11px;
    color: #231F20 ;
    font-family: DIN_Regular;
    position: fixed;
    bottom: 1px;
    height: 25px;
    margin-left: 31px;
}

.ais_license-terms-mobile {
    font-size: 10px;
    line-height: 1.ais_4;
    color: #231F20;
    font-family: DIN_Regular;
    text-align: center;
} */

*:focus {
  outline: none;
}

div:not(#attachmentDiv):focus {
  outline: none;
}

.ais_wordwrap {
  white-space: pre-wrap;
  /* CSS3 */
  white-space: -moz-pre-wrap;
  /* Firefox */
  white-space: -pre-wrap;
  /* Opera <7 */
  white-space: -o-pre-wrap;
  /* Opera 7 */
  word-wrap: break-word;
  /* IE */
}

/*
.ais_formSeperator109 {
    margin: 109px 0px;
}

.ais_formSeperator32 {
    margin: 32px 0px;
}

.ais_formSeperator66 {
    margin: 66px 0px;
}

.ais_formSeperator22 {
    margin: 22px 0px;
}

.ais_formSeperator10 {
    margin: 10px 0px;
}

.ais_formSeperator14 {
    margin: 14px 0px;
}

.ais_formSeperator20 {
    margin: 20px 0px;
}

.ais_formSeperator18 {
    margin: 18px 0px;
}

.ais_elementSeperator {
    margin: 10px 0px;
}

.ais_elementSeperator5 {
    margin: 5px 0px;
}

.ais_elementSeperator22 {
    margin: 22px 0px;
} */
/*
.ais_greyLineSeperator {
    width: 100%;
    height: 1px;
    background-color: #efefef;
} */

/* .ais_nopadding-left-right {
    padding-left: 0px;
    padding-right: 0px;
} */

.ais_btn.ais_active,
.ais_btn:active {
  -webkit-box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0.125);
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* .ais_information-retrival-wrap{
    margin-bottom: 15px;
} */
/* .ais_information-retrival-msg{
  width: 550px;

  font-family: DIN_Regular;
  font-size: 10px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #231F20 ;
} */

/* .ais_get-help-msg{
  width: 520px;
  font-family: DIN_Regular;
  font-size: 10px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #231F20 ;
  margin: 15px 0  0  0;
  position: fixed;
  bottom: 33px;
  margin-left: 31px;

} */

/* .ais_add-br-line{
    display: block;
} */

/* .ais_send-message-button {
    width: 110px;
    height: 40px;
    border-radius: 28px;
    background-color: white;
    font-size: 16px;
    font-weight: bold;
    border: solid 1px #ed1a3d;
    color: #ed1a3d;

} */
/*
.ais_input-fields-container {
    bottom: 0px;
    position: fixed;
    width: inherit;
} */

/* .ais_message-input-textbox-div {
    width: 78%;
    padding: 0px;
    margin-left: 19px;
    margin-right: 11px;
    margin-bottom: 20px;
} */
/*
.ais_send-message-button-div {
    width: 0%;
    padding: 0px;
    margin-right: 19px;
    margin-bottom: 20px;
} */
/*
.ais_title-panel-bar {

    height: 105px;
} */

/* .ais_message-display-container {
    height: 465px;
    overflow-y: scroll;
} */

/* .ais_hide-div {
    display: none;
} */

/*.ais_container-fluid {
    padding-right: 9px;
    padding-left: 9px;
    overflow: hidden;
}*/
.ais_container-fluid {
  padding-bottom: 5px;
}

.ais_web-container-fluid {
  height: 100%;
  min-height: 100%;
  padding-right: 9px;
  padding-left: 9px;
  overflow: hidden;
}

.ais_center-align {
  text-align: center;
}

.ais_align_centr {
  text-align: center;
  margin-left: 30px;
}

.ais_address_content {
  font-size: 14px;
  font-family: Helvetica;
  /* height: 90px; */
  text-align: center;
}

.ais_fee_calculation {
  font-size: 14px;
  font-family: Helvetica;
  font-weight: bold !important;
  margin: 12px 10px 12px 10px;
}

.ais_fee_align {
  font-size: 14px;
  font-family: Helvetica;
  text-align: right !important;
  font-weight: bold !important;
  margin: 10px 10px 0px 10px;
  width: 50%;
}

.ais_fee_align.data {
  font-weight: normal !important;
  text-align: left !important;
  width: 30%;
}


.ais_address_align {
  font-size: 14px;
  font-family: Helvetica;
  /* height: 90px; */
  text-align: center;
  font-weight: normal;
  text-align: left;
}

.ais_align_rgt {
  text-align: right;
}

.ais_align_left {
  text-align: left;
}

.ais_address_padding {
  padding-right: 2px;
  padding-left: 2px;
}

.ais_center {
  width: 50%;
  text-align: left !important;
  display: inline-table;
}

.ais_btn-field {
  /*width: 120px !important;*/
  padding: 10px !important;
  border: none;
  border-radius: 45px;
  background-color: #999999;
  margin-top: 20px;
  color: #ffffff;
  font-size: 15px;
  line-height: initial;
  /* height: 40px; */
}

.ais_button-field {
  width: 180px !important;
  padding: 10px !important;
  border: none;
  border-radius: 45px;
  background-color: #999999;
  margin-top: 20px;
  color: #ffffff;
  font-size: 15px;
  line-height: initial;
  /* height: 40px; */
}

.ais_container-wrapper-mw {
  border: solid 1px #cccccc;
  border-radius: 2px;
  max-width: 500px !important;
  min-width: 260px !important;
  overflow: auto;
  background-color: #ffffff;
  margin: auto;
  line-height: initial;
  overflow-x: hidden;
  white-space: pre-wrap;
  margin-top: 10px;

  padding-left: 40px;
  padding-right: 40px;
  padding-top: 50px;
  padding-bottom: 50px;
}

.ss_pos-relative {
  position: relative;
}

.ais_app-body {
  height: 100%;
  padding-top: 110px;
}


.ais_app-message-window {
  background-color: white;
  height: 648px;
  /*display: none;*/
}

select {
  -moz-appearance: select;
}

.ais_no-padding {
  padding-left: 0px;
  padding-right: 0px;
}


/**********Start of Login Styles*********/

/* .ais_custom-form-group {
    margin-bottom: 10px;
} */

/* .ais_captcha-styles {
    margin-top: 20px;
    margin-left: 30px;
    transform: scale(0.ais_77);
    -webkit-transform: scale(0.ais_77);
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
    margin-bottom: 30px;
} */

/* #loginPageHeader1 {
    font-family: DIN_Regular;
    font-size: 24px;
    text-align: left;
    color: #231f20;
    font-weight: 500;
    line-height: normal;
} */

/* .ais_loginPageHeader2 { */
/* font-family: DIN_Regular;
    font-size: 24px;
    text-align: center;
    color: #231f20; */
/* font-family: DIN_Medium; */
/* } */

/* #img-logo-mswop-tab {
    display: block;
} */

/* #img-logo-mswop-mobile {
    display: none;
} */

/* .ais_login-page-form { */
/* width: 300px;
    margin-top: 20px; */
/* top: 125px; */
/* position: absolute; */
/* left: 57px; */
/* } */

/* .ais_form-control-manual {
    height: 40px;
    border-radius: 8px;
    border: solid 1px #d6d6d6;
    font-family: DIN_Regular;
    font-size: 14px;
    color: #231f20;
} */

/* .ais_form-control-select-manual {
    height: 40px !important;
    border-radius: 8px !important;
    border: solid 1px #d6d6d6 !important;
    font-family: DIN !important;
    font-size: 14px !important;
    color: #231f20 !important;
    width: 300px !important;
} */

#startChatNow {
  border-radius: 24px;
  border: solid 1px #ed1a3d;
  background-color: #ed1a3d;
  color: #fff;
  font-size: 16px;
}

/* #startChatNow:hover {
    background-color: #ed193d;
    border: solid 1px #ed193d;
    color: #ffffff;
} */

#endChatId {
  margin-top: 5px;
  border-radius: 20px;
  border: solid 1px #80c343;
  background-color: white;
  font-weight: bold;
  color: #80c343;
}

#endChatId:hover {
  background-color: #80c343;
  border: solid 1px #80c343;
  color: #ffffff;
}

/* .ais_start-chat-login {
    width: 300px;
    text-align: left; */
/* margin-top: 20px; */
/* } */

/* .ais_login-page-form label {
    font-family: DIN_Regular;
    font-size: 14px;
    color: #231f20;
} */

/* .ais_img-welcome-mobile {
    display: none;
    width: 100%;
    bottom: 0;
    position: fixed;
    z-index: -10;
} */

.ais_captcha-div {
  position: relative;
  padding-right: 50px;
}

.ais_input-group-btn .ais_refresh-icon {
  position: absolute;
  right: 0px;
  left: auto;
  top: 0px;
  width: 40px;
  height: 40px;
}

.ais_captcha-div input {
  display: inline-block;
  border-radius: 8px;
  margin-bottom: 15px;
}

.ais_refresh-icon button {
  border-radius: 8px;
}

/**********End of Login Styles*********/

/**********Start of OFF hours*********/

/*#offHoursDiv {
    margin-top: 40px;
}*/

/* .ais_off-hours-header { */
/*font-family: AvenirNextLTProLight;
    font-size: 24px;
    font-weight: 500;*/
/* font-family: DIN_Light;
    font-size: 32px;
    font-weight: normal;
    color: #231f20;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
} */

/* .ais_off-hours-body { */
/*width: 650px;
    font-family: AvenirNextLTProLight;
    font-size: 18px;
    font-weight: 500;*/
/* width: 700px;
    font-family: DIN_Light;
    font-size: 24px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #231f20;
} */

/* .ais_offline-hours-body { */
/*width: 650px;
    font-family: AvenirNextLTProLight;
    font-size: 18px;
    font-weight: 500;*/
/* width: 650px;
    font-family: DIN_Light;
    font-size: 20px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #231f20;
    margin-top: 10px;
} */

/* .ais_offline-textarea {
    margin: 10px 0px 0px;
    width: 600px;
    height: 110px;
    border-radius: 8px;
    border: 1px solid rgb(214, 214, 214);
    resize: none;
    padding: 10px;
    font-family: DIN_Regular;
} */

/* .ais_offline-msg-footer-margin {
    margin-left: -75px;
} */
/* .ais_offline-submit {
    width: 100px;
    height: 40px;
    border-radius: 20px;
    background-color: #80c343;
    border: solid 1px #80c343;
    font-size: 16px;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    font-family: DIN_Regular;
    margin-top: 10px;
} */

/* .ais_off-hours-data {
    font-family: DIN_Medium;
} */

/**********End of OFF hours*********/

/******START New Changes (Removal of Request Type & EmailID)*****/

/* .ais_login-page-header {
    top: 30px;
} */

/* .ais_custom-form-group {
    margin-bottom: 10px;
} */

/* .ais_offline-footer {
    position: fixed;
    bottom: 0;
} */
/******END New Changes (Removal of Request Type & EmailID)*****/

/***Captcha***/
/*
.ais_captach-validate-txt {
    text-align: left;
    width: 100%;
} */

/* .ais_captcha-refresh-btn {
    padding: 0px;
    border: none;
    background-color: white !important;
    outline: none;
    height: 46px !important;
} */

/* .ais_captcha-refresh-btn:active {
    color: white;
    background-color: white;
    border-color: white;
    outline: none;
} */
/* .ais_captcha-refresh-btn:focus,.ais_captcha-refresh-btn:active:focus,#startChatNow:focus,#startChatNow:active:focus{
    outline: none;
}
.ais_captcha-generate-left {
    float: left;
    width: 80%;
}

.ais_captcha-generate-right {
    float: right;
    width: 13%;
}

.ais_captcha-generate-main {
    margin-bottom: 55px;
} */
/*
#mainCaptcha {
    text-align: center;
    background-image: url('.ais_.ais_/images/common/captcha-bg-img.ais_JPG');
    height: 50px;
    border-radius: 0px;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
} */

/* .ais_captcha-refresh-icon {
    width: 32px;
    height: 32px;
    background-image: url('.ais_.ais_/images/common/ic-refresh.ais_png');
    display: block;
} */

/* .ais_captcha-refresh-icon:hover {
    background-color: white;
    border-radius: 0px;
} */

/* #offWorkingHoursMessage {
    width: 675px;
    text-align: left;
} */

/* #notRobotLabel {
    vertical-align: middle;
    margin-left: 15px;
    margin-top: 8px;
} */

/* #notRobotCheckbox {
    width: 20px;
    height: 20px; */
/* margin-top: 3px; */
/* vertical-align: middle;
} */

/* .ais_incorrectNumberBtn {
    height: 30px;
    border-radius: 8px;
    border: solid 1px #d6d6d6;
    font-family: DIN_Regular;
    font-size: 14px;
    color: #231f20;
} */

table {
  border-spacing: 0 !important;
  border-collapse: collapse !important;
  padding: 0 !important;
  margin: auto !important;
}

td {
  vertical-align: top;
}

/***End of Captcha***/

/*******************************
* Does not work properly if "in" is added after "collapse".ais_
* Get free snippets on bootpen.ais_com
*******************************/
.ais_panel-group .ais_panel {
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-color: #eeeeee;
  border: none;
}

.ais_panel-group .ais_panel:last-of-type>.ais_panel-heading {
  border-bottom: none;
}

.ais_panel-default>.ais_panel-heading {
  padding: 0;
  border-radius: 0;
  color: #212121;
  background-color: white;
  border: none;
  border-bottom: solid 1px #d6d6d6;
}

.ais_panel-title {
  font-size: 14px;
  font-family: DIN_Regular;
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  color: #231f20;
}

.ais_panel-title>a {
  display: block;
  padding: 15px;
  text-decoration: none;
}

/* .ais_more-less {
        float: right; */
/* color: #d6d6d6; */
/* color: #009cdb;
        font-size: 14px;
    } */
/*    .ais_glyphicon{
        font-family: inherit;
    }*/

.ais_panel-default>.ais_panel-heading+.ais_panel-collapse>.ais_panel-body {
  border-top-color: #eeeeee;
  font-family: DIN_Regular;
  font-size: 14px;
  text-align: left;
  color: #231f20;
}

#accordion {
  height: 500px;
  margin: 20px auto;
  overflow-y: auto;
  width: 86%;
}

.ais_blue {
  color: #009cdb;
}

.ais_privacy-header {
  height: auto;
}

/* .ais_privacy-body{
        height: auto;
    } */

/* .ais_privacy-main{
        height: auto;
    } */

/* .ais_privacy-home-button{
        margin-left: 44px;
        margin-top: 20px;

    } */

/* .ais_live-chat-support-class{
        font-size: 16px;
        font-family: DIN_Regular;
        color: #009cdb;
    }
/*  */
/* .ais_privacy-home-button a:link {
        color: #009cdb;
    }

    .ais_privacy-home-button a:visited {
        color: #009cdb;
    }

    .ais_privacy-home-button a:hover {
        color: #009cdb;
    }

    .ais_privacy-home-button a:active {
        color: #009cdb;
    } */

/* .ais_privacy-policy-anchor{
        color: #707c82;
    }

    .ais_terms-conditions-table table {
        border-collapse: collapse;
    }

    .ais_terms-conditions-table table, .ais_terms-conditions-table td, .ais_terms-conditions-table th {
        border: 1px solid black;
    }

    .ais_terms-conditions-table th, .ais_terms-conditions-table td {
        padding: 10px;
        text-align: center;
    }
    .ais_terms-conditions-table th{
        background-color: lightgrey;
    }


.ais_table-responsive{
    width:100%;
    border:none;
    height:100%;
}

.ais_align-top-header-table{
  display: table-cell;
  vertical-align: top;
  height: 100px;
}

.ais_align-bottom-header-table{
    display: table-cell;
    vertical-align: bottom;
}





#pageNotFound a:active,
#pageNotFound a:hover,
#pageNotFound a:visited{
color:#80c343
}
/*Modal PopUp CSS*/

/* .ais_delivery-container {
    max-width: 307px;
    margin: 0 auto;
    width: 100%
} */

/* .ais_pad-top{
    margin-top: 20px !important;
} */

/* .ais_delivery-popup .ais_cancel {
    -webkit-box-shadow: none
} */

/* .ais_delivery-popup a,.ais_delivery-popup input,.ais_delivery-popup label {
    width: 100%;
    margin: 4px 0;
    padding: 0
} */

/* .ais_delivery-popup .ais_continue {
    height: 50px;
    line-height: 50px;
    width: 275px
} */

/* .ais_delivery-popup .ais_continue:hover,.ais_delivery-popup .ais_continue:visited {
    background: none repeat scroll 0 0 #0068A6;
    color: #fff;
    transition: none
} */

/* .ais_delivery-popup .ais_error {
    color: red;
    text-align: left;
    font-size: 12px
} */

/* .ais_delivery-popup input[type=tel],.ais_delivery-popup input[type=text] {
    width: 100%;
    padding: 0 0 0 4%;
    margin-top: 0;
    margin-bottom: 10px;
    height: 44px;
    border-radius: 8px;
    line-height: 45px;
    border: solid 1px #e7e9ea;
    background-color: #ffffff;
    -webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
} */

/* .ais_delivery-popup .ais_mainlabel {
    font-size: 12px;
    font-weight: 700;
    padding: 0;
    margin-bottom: 0
}

.ais_delivery-popup .ais_cancel {
    height: 50px;
    line-height: 50px;
    width: 275px;
    background: 0 0;
    color: #666;
    text-shadow: none;
    box-shadow: none;
    webkit-box-shadow: none;
    display: none
} */
/*

.ais_modal-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1040;
    background-color:rgba(0, 0, 0, 0.ais_3);
} */

/* .ais_show-me{
    display:block;
} */

/* .ais_hide-me{
    display:none;
} */

.ais_hide {
  display: none;
}

.ais_show {
  display: block;
}

/*
.ais_popup-field-font{
    font-family: "Avenir_Book";
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    color: #8589a1 !important;
} */

/* .ais_negateDatePickerSpanStyle{
    padding: 0px;
    font-size: 0px;
    font-weight: 0;
    line-height: 0;
    color:transparent;
    text-align: center;
    background-color:transparent;
    border: 0px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
} */

.datePicker-inputBorderRight {
  width: 100%;
  padding: 0 0 0 4%;
  margin-top: 0;
  margin-bottom: 10px;
  height: 44px;
  border-radius: 8px !important;
  line-height: 45px;
  border: solid 1px #e7e9ea;
  background-color: #ffffff;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.datepicker.dropdown-menu {
  width: 307px !important;
  /* font-family: "Avenir_Book" !important; */
  height: 336px !important;
  box-shadow: none !important;
  top: 144px;
  border-radius: 16px !important;
  padding: 0px 0px 0px 0px !important;
}

.table-condensed {
  width: 305.5px !important;
  height: 294px !important;
}

.table-condensed tbody tr {
  border-top-color: rgb(231, 233, 234);
  border-top-style: solid;
  border-top-width: 1px;
  border-image-source: initial;
  border-image-slice: initial;
  border-image-width: initial;
  border-image-outset: initial;
  border-image-repeat: initial;
}

/* .table-condensed tbody:last-child{
    border-bottom: none !important;
} */

.table-condensed td {
  vertical-align: inherit !important;
}

.datepicker-dropdown:before,
.datepicker-dropdown:after {
  content: none !important;
}

.datepicker table tr td.disabled,
.datepicker table tr td.disabled:hover,
.datepicker table tr td.new {
  color: #8589a1 !important;
}

/* .ais_ios6FamilySafari{
    top:41px !important;
}

.ais_ios6FamilyChrome{
    top:81px !important;
}

.ais_ios6FamilyWinChrome{
    top:158px !important;
}

.ais_ios6PlusFamilyWinChrome{
    top:226px !important;
}

.ais_iosXFamilySafari{
    top:130px !important;
}

.ais_iosXFamilyChrome{
    top:170px !important;
}

.ais_ios5Family{
    top:160px !important;
}

.ais_ios6PlusFamilySafari{
    top:119px !important;
}

.ais_androidNexus5XFamily{
    top:91px !important;
}

.ais_android640HeightFamily{
    top:48px !important;
} */

i {
  /* border: solid #fff;
  border-width: 0 3px 3px 0; */
  display: inline-block;
  padding: 3px;
}

.ais_right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.ais_left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

/* The Close Button */
.ais_close {
  color: #000;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.ais_errorAlert {
  border: 1px solid #ed1a3d !important;
}

a.ais_cancelBtn {
  font-size: 14px !important;
  font-weight: 500;
  letter-spacing: normal;
  background-color: #ffffff;
  color: #ed1a3d;
  cursor: pointer !important;
  text-align: center;
  display: inline-block;
  width: 50%;
  height: auto;
  line-height: 40px;
  border: none;
  border-radius: 0px;
}

a.ais_continue {
  font-size: 14px !important;
  font-weight: 500;
  /* width: 300px;
    height: 45px; */
  background-color: #ed1a3d;
  color: #ffffff;
  cursor: pointer !important;
  text-align: center;
  margin-left: 0px !important;
  height: auto;
  line-height: 40px;
  width: 166px;
  border: none;
  border-radius: 0px;
}

a.ais_continue:visited {
  font-size: 14px !important;
  font-weight: 500;
  /* width: 300px;
    height: 45px; */
  background-color: #ed1a3d;
  color: #ffffff;
  cursor: pointer !important;
  text-align: center;
  margin-left: 0px !important;
  height: auto;
  line-height: 40px;
  width: 166px;
  border: none;
  border-radius: 0px;
}

a.ais_continue:active {
  font-size: 14px !important;
  font-weight: 500;
  /* width: 300px;
    height: 45px; */
  background-color: #ed1a3d;
  color: #ffffff;
  cursor: pointer !important;
  text-align: center;
  margin-left: 0px !important;
  height: auto;
  line-height: 40px;
  width: 166px;
  border: none;
  border-radius: 0px;
}

.ais_close:hover,
.ais_close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.ais_expDate {
  display: inline-block;
  width: 43%;
  height: 45px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

/*
.ais_dlvSlot{
    width: 100% !important;
} */

/* .ais_cvvCode{
    width: 50% !important;
    float: right;
    height: 45px;
    margin-top: 0px !important; */
/* margin-right: 8px !important; */
/* padding-left: 16px !important;
    -webkit-text-security: disc;
} */

.ais_hide-visa {
  display: none;
}

.ais_hide-mastercard {
  display: none;
}

.ais_hide-amex {
  display: none;
}

/* .ais_cc-inp{
    width: 100% !important;
    background-color: #fff;
}

.ais_cc-flags{
    float:right !important;
    margin-top: 10px !important;
    margin-bottom: 5px;
    width: 17%;
} */

/* .ais_ccInputDiv{
    position: relative;
}

.ais_ccInputDiv input[type=text]{
    width: 100%;
    padding: 0 0 0 4%;
    margin-top: 0;
    margin-bottom: 10px;
    height: 44px;
    border-radius: 8px;
    line-height: 45px;
    border: solid 1px #e7e9ea;
    background-color: #ffffff;
    -webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
}

.ais_ccInputDiv img {
    position: absolute;
    top: 14px;
    right: 14px;
    bottom: 14px;
} */

/* Add Animation */
@-webkit-keyframes slideIn {
  from {
    bottom: -300px;
    opacity: 0;
  }

  to {
    bottom: 0;
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    bottom: -300px;
    opacity: 0;
  }

  to {
    bottom: 0;
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

#datepicker {
  width: 100%;
  margin: 0 0 0px 0px;
}

#datepicker>span:hover {
  cursor: pointer;
}

.datepicker-days table thead tr:first-child,
.datepicker-days table thead tr:first-child:active,
.datepicker-days table thead tr:first-child:hover {
  -webkit-box-sizing: border-box !important;
  -moz-box-sizing: border-box;
  background-color: #00b9e3;
  color: #fff;
  font-size: 16px;
  height: 42px;
  border-radius: 16px 16px 0px 0px;
}

.datepicker-days table thead {
  width: 300px !important;
}

.table-condensed tr {
  height: 42px;
}

.datepicker-days .ais_dow {
  font-size: 12px;
  color: #8589a1;
  text-align: center;
}

.datepicker-days .ais_day {
  font-size: 12px;
  color: #1d3444;
  text-align: center;
}

.datepicker-days table thead tr:first-child th {
  padding: 4px 0px !important;
  /* -webkit-box-sizing: border-box !important; */
  border-radius: unset;
}

.datepicker-days table thead tr:first-child th.ais_prev,
.datepicker-days table thead tr:first-child th.ais_prev:visited,
.datepicker-days table thead tr:first-child th.ais_prev:active,
.datepicker-days table thead tr:first-child th.ais_prev:hover {
  border-top-left-radius: 16px;
  border-bottom-left-radius: 0px;
}

.datepicker-days table thead tr:first-child th.ais_next,
.datepicker-days table thead tr:first-child th.ais_next:visited,
.datepicker-days table thead tr:first-child th.ais_next:active,
.datepicker-days table thead tr:first-child th.ais_next:hover {
  border-top-right-radius: 16px;
  border-bottom-right-radius: 0px;
}

.datepicker thead tr:first-child th:hover,
.datepicker tfoot tr th:hover {
  /* background: #eeeeee; */
  background: #00b9e3 !important;
}

.datepicker table tr td.ais_today,
.datepicker table tr td.ais_today:hover,
.datepicker table tr td.ais_today.ais_disabled,
.datepicker table tr td.ais_today.ais_disabled:hover {
  /* background-color: #fff !important;
    background-image:none !important; */
  color: #1d3444 !important;
}

.datepicker table tr td.ais_active:hover,
.datepicker table tr td.ais_active:hover:hover,
.datepicker table tr td.ais_active.ais_disabled:hover,
.datepicker table tr td.ais_active.ais_disabled:hover:hover,
.datepicker table tr td.ais_active:active,
.datepicker table tr td.ais_active:hover:active,
.datepicker table tr td.ais_active.ais_disabled:active,
.datepicker table tr td.ais_active.ais_disabled:hover:active,
.datepicker table tr td.ais_active.ais_active,
.datepicker table tr td.ais_active:hover.ais_active,
.datepicker table tr td.ais_active.ais_disabled.ais_active,
.datepicker table tr td.ais_active.ais_disabled:hover.ais_active,
.datepicker table tr td.ais_active.ais_disabled,
.datepicker table tr td.ais_active:hover.ais_disabled,
.datepicker table tr td.ais_active.ais_disabled.ais_disabled,
.datepicker table tr td.ais_active.ais_disabled:hover.ais_disabled,
.datepicker table tr td.ais_active[disabled],
.datepicker table tr td.ais_active:hover[disabled],
.datepicker table tr td.ais_active.ais_disabled[disabled],
.datepicker table tr td.ais_active.ais_disabled:hover[disabled] {
  background-color: #ed1a3d !important;
  font-size: 23px;
  padding: 0px 0px;
  width: 0px;
  height: 5px;
  border-radius: 100px;
  transform: scale(0.5, 0.5);
  color: #fff !important;
}

.datepicker table tr td {
  width: 42px !important;
}

/* .datepicker table tr td.ais_active {
    background-color: #ed1a3d !important;
    font-size:23px;
    padding: 0px 0px;
    width: 0px;
    height: 5px;
    border-radius: 100px;
    transform: scale(0.ais_5, 0.ais_5);
} */

#datepicker {
  position: relative;
  display: inline-block;
}

/* .ais_down-arrow{ */
/* transform: rotate(0deg);
    transition: transform 0.ais_2s linear;
    position: absolute;

			top: 0px;
			right: -8px;
			height: 20px;
			width: 20px;

      z-index:3;
      filter: grayscale(100%);  */
/* transform: rotate(0deg);
      transition: transform 0.ais_2s linear;
      position: absolute; */
/* background-image: url(.ais_.ais_/images/mobile/ic_arrow.ais_png); */
/* top: 6px;
      right: 3px;
      height: 28px;
      width: 17px;
      z-index: 9;
      filter: opacity(39%);
  } */

/* .ais_down-arrow.ais_open{
    transform: rotate(180deg);
    transition: transform 0.ais_2s linear;
  } */

/* .ais_select2{
      width:100% !important;
  } */

/* .ais_select2-container .ais_select2-choice { height: 200px !important; }  */

/* .ais_select2-container{
      font-family:"Avenir_Medium" !important;
      font-size:16px !important;
  }

  .ais_select2-container .ais_select2-selection--single{
      height:44px!important;
  }

  .ais_select2-container .ais_select2-results__option{
    color: #8589a1 !important;
  }

  .ais_select2-container--default .ais_select2-selection--single .ais_select2-selection__rendered{
      line-height: 44px !important;
  }

  .ais_select2-container--default .ais_select2-results__option--highlighted[aria-selected] {
      background-color:#ed1a3d !important;
      color:#fff !important;
  }

  .ais_select2-container--default .ais_select2-selection--single .ais_select2-selection__arrow{
      height:40px !important;
  } */

/* #select2-dlv_slot-container input[title=Select Delivery Slot]{
    font-family:"Avenir_Book";
    font-size:14px;
  } */

/* .ais_defaultOptions{
    font-family:"Avenir_Book";
    font-size:14px;
    color: #8589a1 !important;
    border: solid 1px #e7e9ea !important;
  } */

.ais_select2-container--default .ais_select2-selection--single .ais_select2-selection__rendered {
  color: #8589a1 !important;
}

.ais_app-message-window {
  height: 90vh;
  overflow: hidden;
  position: relative;
  width: 100%;
  margin-top: 60px;
}

.ais_currentTime {
  text-align: center;
  width: 100%;
  /* margin-top: 10px; */
  margin-left: auto;
  margin-right: auto;
  font-size: 10px;
  color: #1d3444;
  position: fixed;
}

#dropdown-size-medium {
  white-space: initial;
  font-size: 14px;
}

.ais_chatBoard {
  /* height: calc(100% - 88px); */
  height: 80%;
  overflow-y: auto;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  margin-top: 20px;
  position: fixed;
  top: 50px;
}

.ais_message-list {
  word-break: break-word;
}

.ais_feedback-parent {
  margin-left: 0%;
  width: 100%;
  text-align: left;
  overflow-y: scroll;
  position: fixed;
  top: 50px;
  height: 90%;
}

#spacer {
  height: 45px;
}

.ais_feedback-parent {
  margin-left: 0%;
  width: 100%;
  top: 80px;
  text-align: center;
}

.ais_feedback-parent-div {
  width: 88%;
  text-align: center;
  margin: auto;
  border: solid 1px #e7e9ea;
  border-radius: 16px;
  text-align: center;
  padding: 25px 20px 0 20px;
}

.ais_feedback-parent-div.ais_feedback-comment {
  width: 580px;
}

.ais_feedback-parent-div .ais_question-label {
  text-align: left;
  margin-bottom: 20px;
  font-size: 15px;
}

.ais_feedback-parent-div .ais_nps-span {
  /* font-size: 17px; */
}

.ais_feedback-parent-div.ais_feedback-comment {
  width: 330px;
}

.ais_feedback-parent-div .ais_feedback-reason {
  font-size: 15px !important;
}

.ais_device-select-button.ais_emi {
  width: 100% !important;
  display: inline-block;
}

.terms-conditions-table th {
  background-color: #bfd22b;
  color: white;
  font-weight: normal;
  padding: 10px;
  text-align: right;
  width: 60%;
}

.terms-conditions-table td {
  padding: 10px;
  text-align: right;
}

.terms-conditions-table table,
.terms-conditions-table td,
.terms-conditions-table th {
  border: 1px solid #000;
}

.ais_app-header {
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 50px;
}
.color-line{
  background: linear-gradient(270deg, #CADD1F 40.5%, #6EA32D 94.75%);
  height: 3px;
}

.firstSlider{
  max-width: 64rem;
  margin:auto;
}
.slide-marker-container {
  margin-top: 1rem; 
  margin-bottom: 1rem;
  width: 100%;
  max-width: 992px;
}
.slide-marker {
  margin-right: 0.25rem;
  margin-left: 0.25rem;
  width: 0.5rem;
}
.slide-marker.highlighted {
  background-color:#bfd22b;
  width: 1.5rem;
}
.secSlider{
  border-color: rgba(204,204,204,var(--tw-border-opacity));
  border-radius: 1rem;
  border-width: 1px;
  height: 100%;
  padding: 1rem;
  overflow: hidden;
  background: #FFFFFF;
  border: 1px solid #E7E9EA;
  box-sizing: border-box;
  border-radius: 10px;
  margin: auto;
}
.text-initial{
  text-align: initial;
}
.section-title{
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  margin-top: 16px;
}
.text-font20{
  font-size: 20px;
  margin-bottom: 1rem;
}
/* .updateLinkSub{
  color:  #6EA32D;
  cursor: pointer;
  margin-top: 10px;
}
.updateLinkText{
  font-weight: bold;
  color: #333333;
  font-family: Helvetica;
  font-style: normal;
  font-size: 16px;
} */
.wrapper{
  text-align: center;
  margin: 10px 10px 0px 10px;
  border: 1px solid #E7E9EA;
  box-sizing: border-box;
  border-radius: 10px;
}
.btn-darkgreenSub {
  background: white;
  color: #6EA32D;
  background: rgba(191, 210, 43, 0.1);
  border: 1px solid rgba(110, 163, 45, 0.5);
  box-sizing: border-box;
  border-radius: 5px;
  margin-top: 2px;
  width: 50%;
}
/*service unavailable css*/
.serviceUnavailableBtn{
  margin: 20px;
  margin-top: 35px;
}
.statusBtn{
  width: 100%;
}
.backToMainMenu{
  text-align: center;
    margin: 28px;
    text-decoration: none;
    font-weight: bold;
    cursor: pointer;
    font-size: 15px;
    color: #6ea32d !important;
}
.btnSub {
  display: inline-block;
  padding: 5px;
  margin-bottom: 0;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.subscriptionLabel{
  background: #EFEFEF;
  border-radius: 5px 5px 0px 0px;
  padding: 4.5px;
  text-align: center !important;
  line-height: 26px !important;
}
.SubscriptionFeecss{
  font-family: Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
}
.subBackToMainMenu{
  margin-top: 10px;
  color: #6EA32D !important;
  cursor: pointer;
}
.subLabelnew{
  font-family: Helvetica;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0px;
  text-align: left;
  display: flex;
  align-items: center;
  color: #8589A1 !important;
  padding:2px 0px;
  margin: 0px;
}
.subLabelnewData{
  font-family: Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
  margin: 0px;
}
.getmore-option {
  box-shadow: 4px 4px 20px rgba(80, 80, 80, 0.25);
  border-radius: 20px;
  cursor: pointer;
  padding: 10px 10px;
  height: 100%;
}
.getmore-option:hover{
  box-shadow: 1px 1px 8px #BFD22B;
}
.dotsCss{
  border-color: black;
}
.carousel {
  width: 100%;
}
.carousel-indicators {
 margin: 0px;
 align-items: center;
}
.carousel-indicators .active {
  width: 12px;
  height: 12px;
  margin: 2;
  background-color:#BFD22B; 
}
.carousel-control.right {
  background-image: none;
  background-repeat: repeat-x;
}
.carousel-indicators li {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 2.5px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #C0C2C4;
  border-radius: 10px;
  border: 0px;
}
.sliderdata{
  width: 100%;
  font-size: 14px;
}
.slide-marker-container {
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 100%;
  max-width: 992px;
}
.deviceIcon{
  width: 55px;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.subscriptionItemsWrapper{
  padding: 20px 0px;
}

.subscriptionItems{
  margin: 5px 15px !important;
}

.subscriptionItemDesc{
  margin-top: 6px;
}

.swipe-devices{
  margin-top: 10px !important;
  font-weight: normal;
  text-align: center;
  margin: 0px;
}

.enrolled-devices{
  margin-top: 5px;
  font-family: Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
}

.proceed-btn{
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
}

.disable-link{
  pointer-events: none !important;
}

.update-device{
  padding-top: 10px;
  font-family: Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: center;
}

.update-device > div:last-child{
  font-weight: 400;
  color:  #6EA32D;
  cursor: pointer;
  padding-top: 5px;
}
.updateEnrollment{
  padding: 10px;
  text-align: center;
}
.enrollment-container{
  margin: 15px auto;
  text-align: center;
}
.enrollment-container > label{
  font-family: Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: center;
}

.device-height-1 > div > div > div{
  height: 600px;
}

.device-height-2 > div > div > div{
  height: 548px;
}

.device-height-sr > div > div > div{
  height: 428px;
}

.non-carousel-wrapper{
  display: flex;
}

.non-carousel-wrapper > div:only-child{
  margin-right: 0px;
}

.non-carousel-wrapper > div:not(:only-child){
  margin-right: 10px;
}

.non-carousel-wrapper > div:not(:only-child):last-child{
  margin-right: 0px;
}


@media only screen and (min-width: 768px) {
  .carousel-indicators{
    display: none;
  }

  .carousel-control{
    display: none
  }

  .swipe-devices{
    display: none;
  }

  .secSlider{
    width: 300px;
    border-color: rgba(204,204,204,var(--tw-border-opacity));
    border-radius: 10px;
    padding: 20.5px 10px;
    background: #FFFFFF;
    border: 1px solid #E7E9EA;
    box-sizing: border-box;
  }

  .secSlider > div:nth-of-type(1) > div:nth-of-type(2) > :not(:first-child){
    margin-top: 4px;
  }

  .wrapper{
    padding: 0px;
    margin-bottom: 0px;
  }

  .subBackToMainMenu{
    margin-top: 10px;
  }

  .update-device{
    padding-top: 20px;
  }
}

.justify-center {
  justify-content: center;
}
.items-center {
  align-items: center;
}
.flex-row {
  flex-direction: row;
}
.flex-col {
  flex-direction: column;
}
.pl-3 {
  padding-left: .75rem;
}
.m-10{
  margin: 10px;
} 
.m-15{
  margin: 15px;
}    
.flex {
  display: flex;
}
.imageDist{
  margin-right: 10px;
}
.fmip-slide, 
.devicelist-slide,
.alternatedevice-slide {
    width: auto;
}
.horizontal-slider {
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  width: 100%;
  max-width: 992px;
  justify-content: space-between;
  flex-direction: column;
}
.horizontal-slider > article {
  margin-right: 0.5rem; 
  margin-left: 0.5rem; 
  scroll-snap-align: center;
}
.horizontal-slider > article:first-child {
  margin-left: 0;
}
.horizontal-slider > article:last-child {
  margin-right: 0;
}
.horizontal-slider::-webkit-scrollbar {
  display: none;
}
.slide-marker-container {
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 100%;
  max-width: 992px;
}
.slide-marker {
  margin-right: 0.25rem; 
  margin-left: 0.25rem; 
  margin-bottom: 0.25rem; 
  display: inline-block;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 0.5rem;
  --tw-bg-opacity: 1;
  background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
}
.slide-marker.highlighted {
  width: 1.5rem;
}

/* Mobile view */
.ais_chat-window{
  padding-bottom: 52px;
}

@media (min-width: 991px) {


  .ais_chat-window {
    position: fixed !important;
    overflow-y: auto;
    height: calc(100vh - 15vh);
    top: 83px;
    padding-bottom: 15px;
  }
  .desktop-no-box-view{
    border: none !important;
    width: 550px !important;
  }

  .terms-conditions-table th {
    background-color: #bfd22b;
    color: white;
    font-weight: normal;
    font-family: Helvetica;
  }

  .terms-conditions-table td,
  .terms-conditions-table th {
    padding: 10px;
    text-align: center;
  }

  .terms-conditions-table td {
    padding: 10px;
    text-align: right;
  }

  .terms-conditions-table table,
  .terms-conditions-table td,
  .terms-conditions-table th {
    border: 1px solid #000;
  }

  .ais_message-list {
    overflow-y: auto;
    margin: 0 auto;
    width: 90%;
    word-break: break-word;
  }

  .ais_row {
    margin-left: -9px;
    margin-right: -9px;
  }

  .ais_feedback-parent {
    margin-left: 0%;
    width: 100%;
    top: 80px;
    text-align: center;
  }

  .ais_feedback-parent-div {
    width: 55%;
    text-align: center;
    margin: auto;
    border: solid 1px #e7e9ea;
    border-radius: 16px;
    text-align: center;
    padding: 25px 20px 0 20px;
  }

  .ais_feedback-parent-div.ais_feedback-comment {
    width: 580px;
  }

  .ais_feedback-parent-div .ais_question-label {
    text-align: left;
    margin-bottom: 20px;
  }

  #spacer {
    height: 5px;
  }
}

.ais_message {
  margin: 10px;
  display: inline-block;
}

.ais_sent-message {
  /* max-width: 85%; */
  margin: 10px;
  display: inline-block;
  float: right;
}

.ais_msg-time {
  font-size: 12px;
  font-weight: 900;
  color: #8589a1;
  padding-bottom: 6px;
}

.ais_message-blk {
  width: 70%;
  margin-bottom: 6px;
  /* float: left; */
}

.ais_sent-message-blk {
  width: 100%;
  float: right;
  word-wrap: break-word;
  margin-bottom: 6px;
}

.ais_sent-msg-time {
  font-size: 12px;
  font-weight: 900;
  color: #8589a1;
  text-align: right;
  padding-bottom: 6px;
}

.ais_info-icon {
  background: url(../images/icons/ic-info.png) no-repeat;
  width: 6%;
  height: 16px;
  float: left;
}

.ais_info-container {
  margin: 15px auto;
  width: 345px;
  text-align: center;
}
.ais_app-header-text-cus {
  font-size: 24px;
  color: #3d3d3d;
  margin-left: 30px;
}

.ais-wrap {
  align-items: center;
}

.ais_app-header-text {
  font-size: 20px;
  color: #3d3d3d;
}

.ais_app-header .ais_close-button-wrapper {
  margin-left: 20px;
}
.ais_close-button-wrapper .ais_close-btn {
  height: 15px;
  cursor: pointer;
}
.ais_options-div {
  width: 350px;
  height: 40px;
  border-radius: 24px;
  background-color: #ffffff;
  border: solid 1px #c0c2c4;
  margin: 10px auto;
  cursor: pointer;
}

.ais_options-selected-div {
  width: 350px;
  height: 40px;
  border-radius: 24px;
  background-color: #bfd22b;
  margin: 10px auto;
}

.ais_options-replacement-div {
  width: 350px;
  height: 40px;
  border-radius: 24px;
  background-color: #ffffff;
  border: solid 1px #c0c2c4;
  margin: 10px auto;
  cursor: pointer;
}
.ais_options-replacement-div:disabled{
  pointer-events: none;
}

.ais_options-replacement-selected-div label,
.ais_options-replacement-div label {
  font-weight: normal;
}

.ais_options-replacement-selected-div {
  width: 350px;
  height: 40px;
  border-radius: 24px;
  background-color: #bfd22b;
  margin: 10px auto;
  border-color: transparent;
}
.ais_options_padding{
  padding: 10px;
}

.ais_choose-option-txt {
  font-size: 30px;
  text-align: center;
  color: #3D3D3D;
  font-family: HelvethaicaAIS;
  line-height: 26px;
  font-weight: bold;
}

.ais_options-label {
  /*   font-family: DBHelvethaicaAISX-75Bd;
  font-size: 18px; */
  font-size: 14px;
  color: #3d3d3d;
  position: relative;
  float: left;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: inherit;
}

.ais_options-selected-label {
  /*   font-family: DBHelvethaicaAISX-75Bd;
  font-size: 18px; */
  font-size: 14px;
  color: #ffffff;
  position: relative;
  float: left;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ais_options-div label,
.ais_options-selected-div label {
  font-weight: normal;
  font-size: 14px;
  text-align: center;
}

.ais_get-help {
  background: url("../images/web/img-btn_chat.png") no-repeat;
  height: 60px;
  bottom: 0px;
  position: fixed;
  width: 100%;
  left: 40%;
}

/* .ais_footer-chatinput {
    bottom: 0;
    position: fixed;
    width: 50%;
} */

.ais_received {
  border-radius: 30px 25px 25px 10px;
  background-color: #f5f5f5;
  /* background-image: linear-gradient(to left, #00b9e3, #0096b3); */
  color: #ffffff;
}

.ais_message-content {
  font-size: 14px;
  color: #3d3d3d;
  padding: 18px;
}

.ais_sent {
  border-radius: 25px 25px 10px 30px;
  /* border-radius: 10px 10px 0px 10px; */
  background-color: #6ea32d;
  color: #1d3444;
  margin: 0px 10px 10px 100px;
}

.ais_sent .ais_message-content {
  color: white;
}

.ais_user-input-div {
  margin-left: 222px;
  background-color: #f5f5f5;
  /* overflow: auto; */
  width: 55%;
  bottom: 20px;
  position: fixed;
}

/* .ais_user-input-textbox-div {
  width: 94%;
  float: left;
} */
.ais_user-input-textbox-div {
  width: 60%;
  margin: 0px auto;
  margin-bottom: 6px;
  /* margin-left: 100px; */
  position: relative;
  bottom: 0;
  left: 0;
  z-index: 10;
}

/* .ais_user-input-textbox {
  background-color: #f5f5f5;
  border: none;
  padding: 10px;
  width: 100%;
  height: 44px;
  font-size: 17px;
} */
.ais_user-input-textbox {
  background-color: #ffffff;
  border: 1px solid #cccccc;
  border-right: 0px;
  border-radius: 3px;
  border-bottom-left-radius: 22px;
  border-top-left-radius: 22px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  padding: 10px;
  width: 85%;
  height: 44px;
  font-size: 17px;
  letter-spacing: normal;
  float: left;
}

.ais_user-input-img-wrapper {
  /* width: 25%; */
  float: left;
  padding: 7px;
  background-color: #ffffff;
  border: 1px solid #cccccc;
  border-left: 0px;
  border-top-right-radius: 22px;
  border-bottom-right-radius: 22px;
  height: 44px;
}

.ais_text-color {
  color: #3d3d3d;
}

.ais_placeholder-color {
  color: #8589a1;
}

.ais_default-send-icon {
  background: url("./../images/ais-images/button-send-normal.png") no-repeat;
  height: 24px;
  width: 25px;
  float: left;
}

.ais_activated-send-icon {
  background: url("./../images/ais-images/button-send-active.png") no-repeat;
  width: 24px;
  height: 25px;
  margin-top: 5px;
}

/* .ais_default-send-icon {
  background: url("./../images/icons/ic-send_default.png") no-repeat;
  height: 24px;
  width: 24px;
  float: right;
  margin: 10px;
}

.ais_activated-send-icon {
  background: url("./../images/icons/ic-send_active.png") no-repeat;
  height: 24px;
  width: 24px;
  float: right;
  margin: 10px;
} */

.ais_transition-panel-cancel-btn {
  /* float: right !important; */
  /* height: calc(100% - 80px); */
  opacity: 0.99;
  /* background-image: linear-gradient(to bottom, #fbfbfb, #f5f5f5); */
  position: fixed !important;
  right: 8px;
  top: 83px;
  /* margin-top: 15px; */
  border: solid 1px #e7e9ea;
  border-radius: 22px;
  background-color: #bfd22b;
  /* margin-bottom: 20px; */
  color: white;
  font-size: 14px;
  line-height: initial;
  padding: 5px 10px 5px 10px;
}


.ais_transition-panel {
  height: calc(100% - 80px);
  opacity: 0.99;
  background-image: linear-gradient(to bottom, #fbfbfb, #f5f5f5);
  overflow-y: auto;
  position: fixed !important;
  right: 0;
  top: 83px;
}

.ais_progress-bar-container {
  width: 240px;
  height: 5px;
  border-radius: 5.5px;
  background-color: #e7e9ea;
}

.ais_progress-bar {
  height: 5px;
  border-radius: 5.5px;
  transition: width 0.5s;
  background-image: linear-gradient(283deg, #cadd1f, #6ea32d) !important;
  /* background-image: linear-gradient(to right, #e32490, #ed1a3d); */
  width: 40%;
}

.ais_app-header-progress {
  font-size: 45px;
  font-weight: 500;
  color: #6ea32d;
}

.ais_app-header-progress>sup {
  font-size: 18px;
  top: -1em;
}

.ais_stage-name {
  font-size: 13px;
  font-weight: 500;
  color: #3d3d3d;
  /* margin-left: 8px; */
}

.ais_stage-progress-div {
  float: right;
  width: 68%;
  margin-top: 20px;
  padding-top: 18px;
  padding-bottom: 20px;
  transition: width 0.5s;
}

.ais_percentage-div {
  float: left;
  width: 25%;
  margin-top: 25px;
}

.ais_blue-circle {
  width: 9px;
  height: 9px;
  background-color: #bfd22b;
  /* background-image: linear-gradient(to bottom, #00b9e3, #0096b3); */
  overflow: auto;
  border-radius: 50%;
  float: left;
}

.ais_blue-dotted-line {
  font-size: 14px;
  font-weight: 500;
  color: #bfd22b;
  margin-left: 30px;
}

.ais_blue-div {
  margin-top: 10px;
  margin-left: 20px;
}

.ais_progress-div {
  overflow: hidden;
}

.ais_grey-line {
  width: 2px;
  height: 38px;
  border-right: dotted 2px #8589a1;
  margin-left: 24px;
  padding-top: 30px;
}

.ais_grey-circle {
  width: 9px;
  height: 9px;
  background-image: linear-gradient(to bottom, #b4b7cc, #8589a1);
  overflow: auto;
  border-radius: 50%;
  float: left;
  margin-top: 3px;
}

.ais_grey-div {
  overflow: hidden;
  margin-left: 20px;
  margin-top: 5px;
}

.ais_step-div {
  font-size: 12px;
  font-weight: 700;
  color: #3d3d3d;
  margin-left: 30px;
}

.ais_enquiry-input-margin {
  /* margin-left: 222px; */
  margin-left: 144px;
}

/* .ais_feedback-parent {
    margin-left: 20%;
    width: 60%;
} */

.ais_question-label {
  font-size: 17px;
  font-weight: 600;
  color: #1d3444;
  text-align: center !important;
}

.ais_nps-span {
  font-weight: 900;
  text-align: center;
  color: #547c22;
  display: block;
}

.ais_feedback-reason {
  height: 120px;
  border-radius: 4px;
  background-color: #f5f5f5;
  width: 100%;
  border: none;
  font-size: 17px;
  color: #8589a1;
  padding: 10px;
  resize: none;
}

.ais_text-color {
  color: #1d3444;
}

.ais_outer-image-div {
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  display: flex;
}

.ais_csat-text {
  display: block;
  margin-top: 5px;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  color: #1d3444;
}

.ais_csat-img {
  margin: auto;
  display: block;
}

.ais_face-img {
  display: inline-block;
  margin: 20px;
}

.ais_feedback-container {
  margin-top: 25px;
  clear: both;
}

.ais_slider-div {
  padding-top: 40px;
  width: 340px !important;
  margin: 0 auto;
}

.ais_finish-div {
  bottom: 10px;
  position: fixed !important;
  width: 20%;
  left: 40%;
  text-align: center;
  height: 40px;
  background-image: linear-gradient(to right, #e32490, #ed1a3d);
  padding: 10px;
  margin-left: 10px;
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
  border-radius: 4px;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #fff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #d8d8d8;
  border-radius: 3.5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #888;
}

/*NEW CODE ENDED*/
/* end of main styles*/

.ais_ReactModal__Body--open {
  overflow: hidden;
}

.ais_current-form-device-div {
  border: solid 1px #e7e9ea;
  border-radius: 16px;
  width: 320px;
  overflow: auto;
  margin: 10px auto 0;
  line-height: initial;
  overflow-x: hidden;
  white-space: pre-wrap;
}
.removeclass{
  overflow-y: hidden;
}

.ais_current-form-device-div.SR{
  width: 315px;
}

.ais_current-form-device-div.list {
  display: inline-block;
  margin-left: 10px;
  height: 258px;
}

.ais_current-form-address-popup {
  border: solid 1px #e7e9ea;
  border-radius: 16px 16px 16px 16px;
  width: 287px;
  overflow: auto;
  background-color: #ffffff;
  margin: auto;
  line-height: initial;
  overflow-x: hidden;
  white-space: pre-wrap;
  margin-top: 10px;
}

.ais_current-form-device-div.ais_display-script {
  width: 90% !important;
  line-height: 1.5;
  /*  font-size: 16px !important; */
}

.ais_current-form-device-div.ais_alternate-device-list {
  /* width: 260px !important; */
  display: inline-block !important;
  margin-right: 10px;
  height: 225px;
}

.ais_current-form-device-div.ais_address-list {
  width: 190px !important;
  display: inline-block;
  margin-left: 15px;
  margin-right: 15px;
}

.ais_scrolling-wrapper {
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  width: 100%;
  text-align: center;
}

.ais_fmip-scrolling-wrapper {
  overflow-x: auto;
  overflow-y: hidden;
  width: 203px;
  text-align: center;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

.ais_fmip-scrolling-wrapper.ais_flex {
  display: flex;
  justify-content: space-between;
}

.ais_fmip-scrolling-wrapper.ais_flex .ais_slide {
  width: inherit;
}

.ais_fmip-inst-wrapper .ais_instruction-wrapper {
  font-size: 13px;
  display: flex;
  justify-content: space-evenly;
  text-align: left;
}

.ais_fmip-inst-wrapper .ais_fmip-illustration {
  display: flex;
  justify-content: space-evenly;
  padding-top: 15px;
}

.ais_fmip-inst-wrapper .ais_fmip-instruction {
  width: 28%;
}

.ais_card {
  display: inline-block;
}

.ais_card.deviceList {
  border: solid 1px #e7e9ea;
  border-radius: 16px 16px 16px 16px;
  padding: 15px;
}

.ais_scrolling-wrapper::-webkit-scrollbar {
  display: none;
  height: 10px !important;
}

.ais_current-form-device-div.ais_sentMessage {
  width: 220px !important;
}

.ais_current-form-device-div.ais_enquiryBox {
  width: 100% !important;
  margin: initial;
}

.ais_current-form-device-div.ais_listEnquiryBox {
  margin: auto;
  margin-bottom: 10px;
  text-align: left;
  width: 70% !important;
}

.ais_current-device-enquiryBox.ais_listEnquiryBox {
  width: 55% !important;
}

.ais_current-form-device-div.ais_mdnVerificationError {
  width: 260px !important;
}

.ais_verificationHeader {
  text-align: center;
  margin-top: 30px;
  margin-bottom: 20px;
}

.ais_verification_error {
  font-size: 13px;
  color: #1d3444;
  text-align: center;
  font-weight: 900;
}

.ais_verificationMessage {
  width: 240px !important;
  margin: auto;
  color: #1d3444;
  font-size: 13px;
  font-weight: normal;
}

.ais_verificationMessage>p {
  margin: 0px !important;
}

.ais_agent-busy-content {
  width: 270px !important;
  margin: auto;
  color: #1d3444;
  font-family: Helvetica;
  font-size: 14px;
  font-weight: normal;
  line-height: 16.1px;
  white-space: normal;
}

.ais_support-hotline {
  text-decoration: none;
  color: #6ea32d !important;
}

.ais_verificationImage {
  margin-top: 12px;
  margin-bottom: 12px;
}

.ais_list-options-icn {
  width: 45px;
  height: 45px;
  margin-right: 15px;
}

.ais_header1-txt-div.ais_listEnquiryBox {
  margin: 15px 15px 15px 15px;
}

.ais_current-form-device-div.ais_features {
  width: 280px !important;
}

.ais_wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  grid-auto-rows: 120px;
  margin: auto;
  margin: 0px 5px 0px 5px;
}

.ais_wrapper.ais_listView {
  display: inline-block;
  /* grid-template-columns: repeat(2, 1fr); */
  /* grid-gap: 10px;
     grid-auto-rows: 120px; */
  margin: auto;
}

.ais_container-div {
  text-align: center;
}

.ais_confirm-details-div {
  border: solid 1px #e7e9ea;
  border-radius: 16px 16px 16px 16px;
  width: 300px;
  overflow: auto;
  background-color: #ffffff;
  margin: auto;
  margin-top: 15px;
  margin-bottom: 20px;
}

.ais_header-txt-div {
  overflow: hidden;
  text-align: center;
  margin-top: 30px;
  margin-left: 20px;
  margin-right: 20px;
}

.ais_header-faq{
    margin: auto;
    overflow: hidden;
    text-align: center;
    margin-top: 30px;
    width: 280px;
}

.ais_header-txt-div.proceedFee{
  margin-left: 28px;
  margin-right: 28px;
}

.ais_header-txt-div.general_enquiry {
  height: 460px;
}

.ais_header-txt-div.ais_docScript {
  margin-left: 10px;
  margin-right: 10px;
}

.ais_header-txt-div.ais_doc {
  margin-top: 0px !important;
}

.ais_header-txt-div.ais_enquiryBox {
  margin: 20px 19px 20px 19px;
}

.ais_header-txt-div.ais_enrolledAddress {
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.ais_header-txt-div.ais_features {
  margin-left: 13px !important;
  margin-right: 13px !important;
}

.ais_confirm-form-details-header-div {
  text-align: left;
  /* margin-left: 15px;
  margin-top: 15px; */
  margin: 15px;
}

.ais_confirm-form-details-header-div.ais_current-request-details {
  padding-bottom: 20px;
}

.ais_confirm-details-blue-label {
  /* color: #abd8dc; */
  color: #6ea32d;
  font-size: 12px;
  font-family: Helvetica-Bold;
  display: inline-block;
  width: 50%;
}

.ais_confirm-details-blue-label-custum {
  /* color: #abd8dc; */
  color: #6ea32d;
  font-size: 12px;
  font-family: Helvetica-Bold;
  display: inline-block;
  width: 50%;
}

.ais_confirm-details-dark-blue-label {
  /*  font-family: Helvetica-Bold; */
  font-weight: bold;
  color: #3d3d3d;
  font-size: 13px;
  display: inline-block;
  width: 50%;
}

.ais_confirm-details-dark-blue-label-custum {
  /*  font-family: Helvetica-Bold; */
  font-weight: bold;
  color: #3d3d3d;
  font-size: 13px;
  display: inline-block;
  width: 50%;
}

.ais_swop-device-header-div {
  margin-top: 15px;
}

.ais_swop-device-img {
  margin-top: 5px;
  display: inline-block;
  margin-top: 8px;
}

/* .ais_swop-device-name-div {
    padding-bottom: 35px;
    width: 70%;
    background-color: #ffffff;
    display: inline-block;
    margin-left: 10px;
} */

.ais_swop-device-name {
  /* font-family: Helvetica-Bold; */
  font-weight: bold;
  font-size: 13px;
  color: #3d3d3d;
  margin-left: 10px;
  display: inline-block;
  width: 42%;
  margin-top: 8px;
  vertical-align: middle;
}

.ais_other-details-desc {
  /* font-family: Helvetica-Bold; */
  font-weight: bold;
  font-size: 13px;
  color: #3d3d3d;
  width: 80%;
}

.ais_triage-device-desc {
  font-size: 13px;
  color: #1d3444;
  width: 100%;
}

.ais_confirm-details-dark-label {
  color: var(--black);
  /* font-family: Helvetica-Bold; */
  font-size: 13px;
  /* display: inline-block; */
  width: 100%;
}

.ais_confirm-details-button {
  width: 90%;
  padding: 13px;
  margin-top: 15px;
  border: solid 1px #e7e9ea;
  border-radius: 24px;
  margin-bottom: 20px;
  background-color: #e7e9ea;
  color: #1d3444;
  font-size: 14px;
}

.ais_confirm-details-button-highlighted {
  width: 90%;
  /* margin-left:45px; */
  padding: 13px;
  margin-top: 15px;
  border: solid 1px #e7e9ea;
  border-radius: 24px;
  background-color: #bfd22b;
  /* background: linear-gradient(to right, #e32490 , #ed1a3d); */
  margin-bottom: 20px;
  color: white;
  font-size: 14px;
}

.ais_bluish-background-selected {
  background-color: #bfd22b;
}

.ais_confirm-delivery-icn {
  margin-top: 10px;
  margin-bottom: 10px;
}

.ais_confirm-delivery-icn.ais_sentMessage {
  margin-bottom: 25px;
}

.ais_align-change-button {
  width: 40%;
  padding: 13px;
  margin-top: 15px;
  margin-left: 10px;
  margin-right: 10px;
  border: solid 1px #e7e9ea;
  border-radius: 5px 5px 5px 5px;
  display: inline-block;
  background-color: #e7e9ea;
  color: #1d3444;
  font-size: 14px;
}

.ais_align-change-button:hover {
  width: 40%;
  padding: 13px;
  margin-top: 1px;
  margin-left: 10px;
  margin-right: 10px;
  border: solid 1px #e7e9ea;
  border-radius: 5px 5px 5px 5px;
  display: inline-block;
  background: linear-gradient(to right, #e32490, #ed1a3d);
  color: white;
  font-size: 14px;
}

.ais_rcorners1:after {
  bottom: 10px;
  content: "";
  position: absolute;
  top: 10px;
  left: 0;
  width: 50px;
  left: -35px;
  background-color: #098a7c;
  border-radius: 70px 100% 100% 70px;
}

.ais_rcorners1 {
  position: relative;
  background: #18b1a0;
  padding: 20px;
  width: 200px;
  overflow: hidden;
  height: 150px;
  border-radius: 10px 130px 130px 10px / 10px 100% 100% 10px;
}

.ais_device-outer {
  width: 300px;
  line-height: 45px;
  border: 1px solid #e7e9ea;
  border-radius: 5px 5px 0px 0px;
  background-color: white;
  margin: auto;
  margin-top: 140px;
  position: relative;
  margin-bottom: 20px;
}

.ais_device-outer.ais_deliveryAddress {
  line-height: 40px;
}

.ais_device-outer.ais_deliverySchedule {
  line-height: 40px;
}

.ais_device-outer.ais_deliveryAddress:after {
  bottom: 385px;
  background: url("../images/ais-images/icon-delivery-address.png") no-repeat center;
}

.ais_device-outer.ais_deliverySchedule::after {
  bottom: 260px;
  background: url("./../images/ais-images/icon-delivery-schedule.png") no-repeat 22px 4px !important;
}

.ais_device-outer.ais_web {
  width: 600px;
  margin-top: 75px;
}

.ais_device-outer.ais_web::after {
  bottom: 250px;
  /* top:-278px */
}

.ais_device-outer.ais_web.ais_doc::after {
  background: url("./../images/ais-images/icon-upload.png") no-repeat center !important;
  background-size: 50% !important;
}

.ais_device-outer.ais_deliverySchedule:after {
  bottom: 264px;
  background: url("./../images/ais-images/icon-delivery-schedule.png") no-repeat 18px 4px !important;
}

.ais_device-outer::after {
  background: url("../images/ais-images/icon-payment-fee.png") no-repeat 21px 10px;
  content: "";
  width: 78px;
  height: 44px;
  position: absolute;
  left: 0;
  right: 0;
  margin: 100px auto;
  border-radius: 30px 30px 0 0;
  border-left: 1px solid #e7e9ea;
  border-top: 1px solid #e7e9ea;
  border-right: 1px solid #e7e9ea;
  border-bottom: 3px solid white;
  top: -144px;
  background-size: 50% !important;
  /* bottom: 110px; */
  /* bottom: 285px; */
}

.ais_closeIcon {
  margin-left: 85%;
  position: relative;
  vertical-align: top;
  margin-top: 8px;
}

.ais_outer-align-input {
  width: 100%;
  text-align: center;
}

.ais_outer-align-input.ais_cc {
  margin-bottom: 5px !important;
  margin-top: 5px !important;
}

.ais_enter-credit-input {
  width: 100%;
  padding: 10px;
  border: solid 1px #e7e9ea;
  border-radius: 5px 5px 5px 5px;
  font-size: 14px;
  color: #8589a1;
  line-height: initial;
}

.ais_enter-credit-input.ais_web {
  width: 45% !important;
  display: inline-block;
}

.ais_enter-credit-expiry-input {
  padding: 10px;
  margin-top: 5px;
  border: solid 1px #e7e9ea;
  border-radius: 5px 5px 5px 5px;
  font-size: 14px;
  color: #8589a1;
  display: inline-block;
  line-height: initial;
  width: 65%;
}

.ais_enter-credit-expiry-input.ais_web {
  width: 45% !important;
}

.ais_enter-credit-cvc-input.ais_web {
  width: 45% !important;
}

.ais_enter-credit-cvc-input {
  width: 30%;
  padding: 10px;
  margin-top: 5px;
  border: solid 1px #e7e9ea;
  border-radius: 5px 5px 5px 5px;
  font-size: 14px;
  color: #8589a1;
  line-height: initial;
}

/* .ais_triangle-down {
	width: 0;
	height: 0;
	border-left: 5px solid transparent;
	border-right: 5px solid transparent;
    border-top: 7px solid #c0c2c4;
    display: inline-block;
} */

.ais_current-terms-div {
  border: solid 1px #e7e9ea;
  border-radius: 16px 16px 16px 16px;
  width: 300px;
  overflow: auto;
  background-color: #ffffff;
  margin: auto;
}

.ais_fmip-instructions-div {
  border: solid 1px #e7e9ea;
  width: 740px;
  height: 567px;
  border-radius: 10px;
  overflow: auto;
  background-color: #ffffff;
  margin: auto;
}

.ais_device-select-button.term {
  width: 50% !important;
}

.ais_fmip-instructions-div .ais_fmip-header-icon {
  padding: 10px 0px 20px 0px;
}

.ais_features .ais_fmip-header-icon {
  margin: 15px 0 20px 0;
}

.ais_header-terms-div {
  text-align: center;
  margin: 30px 20px 20px 20px;
}

.ais_note-cus {
  font-size: 10px;
  font-family: Helvetica;
}


.ais_current-mobile-device-label {
  font-size: 15px;
  color: #1d3444;
  text-align: center;
  margin-bottom: 25px;
  font-weight: 700;
}
.display-none{
  display: none;
}

.ais_current-mobile-device-content1 {
  text-align: center;
  /* margin-bottom: 25px; */
  font-family: Helvetica;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
  align-items: center;
  text-align: center;
  color: #3D3D3D;
}
.ais_current-mobile-device-content2{
display: block;
font-family: DB HelvethaicaAIS X;
font-size: 30px;
font-style: normal;
font-weight: 700;
line-height: 26px;
letter-spacing: 0em;
text-align: center;
margin-bottom: 15px;
}
.ais_current-mobile-device-content3{
font-family: Helvetica;
font-style: normal;
font-weight: 400;
font-size: 15px;
line-height: 18px;
text-align: center;
color: #333333;
}
.green-break-line{
height: 6px;
max-width: 320px;
left: 28px;
top: 204px;
background: #BFD22B;
border-radius: 10px;
margin: 5px auto 30px auto;
}
.break-line{
  background: linear-gradient(270deg, #CADD1F 40.5%, #6EA32D 94.75%);
  height: 3px;
}
.ais-leaving-reasons{
display: flex;
overflow-x: auto;
margin-bottom: 10px;
}
.reasonsWrapper{
display: flex;
margin-bottom: 30px;
flex-wrap: wrap;
}

.reasons{
   height: 150px;
   width: 150px;
   background: #FFFFFF;
   box-shadow: 4px 4px 20px rgba(80, 80, 80, 0.25);
   border-radius: 20px;
   text-align: center;
   margin: 10px;
   padding: 24px;
}

.reason-items{
  padding: 20px 10px;
}

.reasonText{
  font-family: Helvetica;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  color: #3D3D3D;
  margin-top: 10px;
  width: 100px;
  font-weight: bold;
}
.image_pay_only {
  width: 100%;
}
.selected{
  background: #BFD22B;
  color: white;
}
.reasons.disabled {
  pointer-events: none;
}
.margin15{
  margin-top:15px;
}
.ais-not-helpful-content{
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 17px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #3D3D3D;
}
.ais-swap-replace-label{
  font-family: Helvetica;
  display: inline-block;
  margin-bottom: 25px;
  font-weight: 400;
  font-size: 15px;
}
.swap-fee{
  margin: 8px;
}
.replace-fee {
  margin: 15px;
}
.fee{
  font-size: 15px;
  margin-bottom: 7px;
}
.youSave{
  margin-bottom: 7px;
  font-size: 15px;
}
.amount{
  font-size: 20px;
  color: #6EA32D;
}
.swap_replacement_fee{
  display: flex;
  justify-content: center;
}
.amt{
font-weight: bold;
font-size: 20px;
}
.stay-background{
  width:100%;
}
.stay-with-ais{
  position: absolute;
  width: 182px;
  margin-right: -67px;
  left: 416px;
}
.stay-with-ais-button{
  max-width: 175px;
  height: 40px;
  background: #BFD22B;
  border-radius: 50px;
  color: white;
  border: none;
  padding: 10px;
  align-items: center;
  text-align: center;
  margin: 25px;
  font-size: 13px;
}
.stay-with-ais-button-highlighted{
  background: #BFD22B !important;
  color: white !important;
}
.submit-text{
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  align-items: center;
  text-align: center;
  color: #6EA32D;
  margin-bottom: 25px;
  cursor: pointer;
}

.center {
  text-align: center;
}
.left {
  text-align: left;
}
.float-right{
  float: right;
}
.modal-top{
  top:200px;
  /* width: 50%; */
}
.acknowledge{
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #6EA32D;
  cursor: pointer;
}
.acknowledge-text{
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #3D3D3D;
}
.acknowledge_comment_text{
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 25px;
  color: #3D3D3D;
  margin-top: 20px;
  margin-bottom: 10px;
}
.textarea_border{
  border: none;
  font-size: 14px;
  width: 100%;
  resize: none;
  word-break: unset;
}
.text_area_limit{
  font-size: 12px;
}
.acknowledge_comment{
  box-sizing: border-box;
  margin-top: 20px;
  width: 100%;
  font-size: 14px;
  background: #FFFFFF;
  border: 1px solid #E7E9EA;
  border-radius: 15px;
  padding:10px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.selectSlot{
  height: 35px;
  width: 100%;
  background: #FFFFFF;
  border: 1px solid #E0E0E0;
  box-sizing: border-box;
  border-radius: 10px;
  margin-bottom: 10px;
  -webkit-appearance: none;
  padding: 8px;
}

.datepicker{
  display: flex;
  max-width: 275px;
  font-size: 14px;
}
.calendarIcon{
  margin-top: 13px;
  z-index: 1;
  position: absolute;
  margin-left: 233px
}
.drop_down_arrow {
  margin-top: 13px;
  z-index: 1;
  position: absolute;
  margin-left: 233px;
  margin-top:4px;
}
.react-datepicker__input-container {
  width:269px;
  position: relative;
  display: inline-block;
  border:none  !important;
  margin:5px;
}
.callbackWindow{
  font-size: 17px !important;
  line-height: 15px !important;
  margin-top: 10px;
  font-family: HelvethaicaAIS;
  font-style: normal;
  font-weight: bold;
  text-align: center;
  color: #3D3D3D;
  padding-bottom: 30px;
}
.footerText {
  margin-top: 10px;
  margin-bottom: 10px;
}

.confirmation {
  font-family: Helvetica;
  font-style: normal;
  font-size: 15px !important;
  line-height: 15px;
  font-weight: 400;
}
.disabled{
  pointer-events: none;
}
.ThankYouContent{
  padding: 30px;
  font-size: 15px;
  padding-top: 70px;
}
.GoodByeText {
  font-family: HelvethaicaAIS;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 30px;
  text-align: center;
  color: #3D3D3D;
}
.thankyou-label{
  font-family: HelvethaicaAIS;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 24px;
  text-align: center;
  color: #3D3D3D;
  padding-bottom: 30px;
  padding-top: 30px;
}
.thankyou-header{
  padding: 10px;
}
.margin124{
  margin-top: 61px;
}
.thankyou-text{
  /* font-family: HelvethaicaAIS; */
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 15px;
  text-align: center;
  color: #3D3D3D;
  margin-top: 8px;
  margin-bottom: 25px;
}
.thankyou-btn{
  background: #BFD22B;
  border-radius: 50px;
  align-items: center;
  text-align: center;
  font-size: 13px;
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  max-width: 150px;
  height: 40px;
  border: none;
  margin: auto;
  color: white;
  padding: 10px;
}
.thankyou-img{
  bottom: 0px;
  position: absolute;
}
.effectiveLink{
  padding: 12px 48px 12px 48px;
  color: white !important;
 text-decoration: none !important;
 font-size: 13px;
}

.desktop_img{
display: none;
}
/* .mobile_desktop_img{
    background: url('../images/ais-images/img-end-screenmobile.svg') 100% no-repeat;
    height: 549px;
} */
.ais-label{
  font-family: HelvethaicaAIS ;
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 20px;
  text-align: center;
  color: #3D3D3D;
  margin-bottom: 10px;
}
.subLabel {
  font-family: HelvethaicaAIS ;
  font-style: normal;
  font-size: 15px;
  text-align: center;
  color: #3D3D3D;
  margin-bottom: 8px;
}

.costTooMuch {
  font-weight: 700;
  margin: 5px;
  color: #6EA32D;
}
.costTooMuchAmt{
  font-size: 20px;
}

.costTooMuchLbl{
  font-size: 13px;
  padding: 8px;
}
.costTooMuchFooterText{
  font-weight: bold;
  font-family: Helvetica;
  font-style: normal;
  font-size: 15px;
  align-items: center;
  text-align: center;
}

.json-text {
  font-size: 10px;
  margin-top: 30px;
  margin-bottom: 15px;
}

.background {
  max-width: 229px !important;
}

.icon {
  position: absolute;
  left: 180px;
}

.icon-image {
  position: absolute;
  left: 100px;
}

.flex {
  display: flex;
}

.reverse {
  flex-direction: row-reverse;
}

.reverse-margin {
  position: absolute;
  left: 55px;
}

.payOnly {
  display: flex;
  font-size: 12px;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 10px;
}
.termeAndConditions{
  color: #3D3D3D !important;
  text-decoration: underline !important;
}
/* WBKO CSS*/

.wbkoVerifyMdn{
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
  /* display: flex; */
  align-items: center;
  text-align: center;
  color: #3D3D3D;
}
.wbko-landing-page-content{
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  align-items: center;
  text-align: center;
  color: #3D3D3D;
  padding: 20px;
}

.wbko-proceed-button {
    width: 30%;
    height: 40px;
    background: #BFD22B;
    border-radius: 50px;
    color: white;
    border: none;
    margin: 15px;
    font-size: 13px;
}
.wbkoDeliveryFlow{
  margin: 20px;
  margin-top: 0px;
  display : inline-flex;
  width: 61%;
}
.wbkoDeliveryFlowContent {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  color: #3D3D3D;
  margin-right:5px;
  margin-left : 10px;
}
.wbkoDefaultStatusText{
  color: #c0c2c4 !important;
}
.wbkoDeliveryFlowTime{
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: #8589A1;
  margin-top:5px;
}
.wbkoThankYouImg{
  margin-top: 10px;
}
.wbkoThankYouText{
  margin-left:20px;
  margin-bottom: 20px;
  font-weight: bold;
  font-family: HelvethaicaAIS;
  font-size: 35px;
}
.wbkoDateIcon{
  margin-right: 5px;
}
.wbkoChangeDeliveryScheduleText{
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  /* line-height: 15px; */
  /* display: flex; */
  align-items: center;
  text-align: center;
  color: #3D3D3D;
}
.wbkoChangeDeliveryDate{
  position: relative;
  width: 100%;
}
.wbkoChangeDeliveryDateIcon{
  margin-top: 14px;
  position: absolute;
  margin-left: 235px;
}
.wbkoConfirmDeliverySchedleText {
  padding: 15px;
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
  align-items: center;
  text-align: center;
  color: #3D3D3D;
  background: rgba(191, 210, 43, 0.1);
  border-radius: 5px;
}

.wbkoCancelDeliveryTextArea {
  background-color: #FFFFFF  !important;
  border: 1px solid #E7E9EA  !important;
  box-sizing: border-box;
  border-radius: 10px !important;
}
.wbkoUpdateContactDetailsInput{
  background: #FFFFFF;
  border: 1px solid #E7E9EA;
  box-sizing: border-box;
  border-radius: 20px;
  width: 100%;
  padding: 8px;
}
.wbkoMarginTop25 {
  margin-top:  10px;
  font-size : 12px;
}
.wbkoInputbox {
  border: none;
  width: 100%;
  font-size: 13px;
}
.wbkoMargin8 {
  margin: 8px;
}
.wbkoMargin15{
  margin: 15px;
}

.wbkoUpdateContactDetailsBtn {
  width: 100px;
  height: 40px;
  background: #BFD22B;
  border-radius: 50px;
  color: white;
  border: none;
  /* padding: 10px; */
  align-items: center;
  text-align: center;
  margin: 15px;
  font-size: 13px;
}
.wbkoRemoveExpandIcon{
  -webkit-appearance: none;
}
.wbkoSrHeader{
  margin: 20px;
  font-weight: bold;
  font-size: 13px;
}
.wbkoSrDetails{
 display : inline-flex;
 width: 100%;
 text-align: left;
}
.wbkoPadding5left{
  text-align: right;
  /* padding: 5px; */
  width : 50%;
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #8589A1;
  margin-right: 6px;
}
.wbkoPadding5right{
  /*  padding: 5px; */
   width : 50%;
   text-align: left;
   font-family: Helvetica;
   font-style: normal;
   font-weight: normal;
   font-size: 12px;
   color: #3D3D3D;
}
.WbkoCancellationLandngHeader{
  font-family: HelvethaicaAIS;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  text-align: center;
  color: #3D3D3D;
}
.wbkoTrackOrEndheader{
  font-family: 'HelvethaicaAIS';
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  text-align: center;
  color: #3D3D3D;
}
.wbkoCheckDeliverySttausDHL{
  background: #6EA32D;
  color: #fff !important;
  padding: 9px;
  margin-top: 15px;
  border: solid 1px #e7e9ea;
  border-radius: 22px;
  margin-bottom: 20px;
  font-size: 14px;
  line-height: normal;
  line-height: initial;
  text-decoration: none !important;
}
.wbkoShippedTrack {
  text-align: center;
  padding: 0px;
  padding-bottom: 30px;
}
.wbkoais_note-div {
  font-size: 12px;
  text-align: center;
  line-height: 1.5;
  margin:  15px;
}
.wbkoRequestForMDN{
  margin-top: 40px;
}
.margin-10{
  margin: 10px;
}
.wbko-address-wrapper{
  padding: 15px 10px 0px 10px;
}
.wbko-address-item{
  display: flex;
}
.wbko-ais_address_padding {
  padding-right: 4px;
  padding-left: 4px;
}
.wbko-ais_address-label-align{
  font-weight: 0 !important;
}
.wbko-asi-address-text-align{
  font-family: Helvetica;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #3D3D3D;
}
.wbko-ais_outer-align-button {
  width: 100%;
  text-align: center;
}
.wbkoPadding10{
  padding: 10px !important;
}
.wbkoPadding15 {
  padding: 13px !important;
}
.wbkoChangeAddressAlign{
  font-size: 15px;
  color: #1d3444;
  text-align: center;
  margin-bottom: 25px;
  font-weight: 0 !important;
}
.wbkoMarginTop{
  margin-top: 10px !important;
}
.wbkoChangeAddressHeader{
  margin-left: 14px;
  margin-right: 14px;
}
.wbkodisplaycontent{
  display: content !important;
}
.ais_ui-wrapper_reason {
  margin-top: 100px;
  margin-right: 100px;
  margin-left: 35px;
  background-color: #f5f5f5;
  border-radius: 40px;
  border: solid 1.5px #f5f5f5;
}
.other_reason_text {
  font-size: 16px;
}

.ais_current-mobile-device-label.script {
  font-weight: 200 !important;
  font-family: Helvetica-Bold;
}

.ais_current-mobile-device-label.faq{
  font-weight: 200 !important;
  font-family: Helvetica-Bold;
  color: #6ea32d;
}

.ais_current-mobile-device-label-header {
  /* font-family: Helvetica-Bold; */
  font-size: 15px;
  color: #1d3444;
  text-align: center;
  margin-bottom: 5px;
  /*margin-left:50px;*/
  /* line-height: 2; */
  font-weight: 700;
}

.ais_current-mobile-device-label-cus {
  /* font-family: Helvetica-Bold; */
  font-size: 15px;
  color: #1d3444;
  text-align: center;
  margin-bottom: 5px;
  /* margin-left:50px; */
  /* line-height: 2; */
  font-weight: 700;
}


.ais_current-mobile-device-content {
  font-size: 13px;
  color: #1d3444;
  font-family: Helvetica;
  text-align: center;
  font-weight: 900;
  padding: 10px 0 10px 0;
}

.ais_download-instructions-header {
  /* font-size: 16px !important; */
}

.ais_download-caveat {
  font-size: 14px;
  padding-bottom: 20px;
  color: #1d3444;
}

.ais_download-instructions {
  /* font-family: DBHelvethaicaAISX-75Bd; */
  font-size: 13px;
  color: #1d3444;
  text-align: justify;
  line-height: 1.68;
  /* font-weight: 200; */
}

.ais_download-container {
  width: 600px !important;
}

.ais_instruction-section {
  width: 96%;
  padding-left: 19px;
}

.ais_current-device-enquiryBox {
  font-size: 12px;
  text-align: center;
  color: #3d3d3d;
  cursor: pointer;
  font-family: Helvetica;
  margin: 4px;
}

.ais_current-device-enquiryBox-clicked {
  font-size: 12px;
  text-align: center;
  /* color: white; */
  cursor: pointer;
  font-family: Helvetica;
}

.ais_container-terms-div {
  text-align: center;
  margin-left: 20px;
  margin-right: 20px;
}

.ais_current-EMI-icn {
  background: url("../images/mobile/img_imei.png") no-repeat;
  height: 180px;
  background-color: #ffffff;
  background-position: center;
}

.ais_current-form-device-name {
  font-size: 14px;
  color: #3d3d3d;
  text-align: left;
  line-height: normal;
  font-family: Helvetica-Bold;
}

.ais_current-form-device-name.ais_address-list {
  text-align: center;
}

.ais_current-form-device-main-name {
  font-family: Helvetica-Bold;
  font-size: 13px;
  color: #3d3d3d;
  text-align: left;
}

/* .ais_popperName {
line-height: initial;
transform:translate3d(20px, -244px, 0px) !important;

} */
/* ChangeDeliverySchedule */

.ais_change-delivery-datePicker-input {
  background: url("../images/mobile/downArrow.png") no-repeat right;
  background-position: 220px;
  width: 255px;
  padding: 13px;
  border: solid 1px #e7e9ea;
  border-radius: 5px 5px 5px 5px;
  font-size: 13px;
  color: #8589a1;
  margin: auto;
  display: block;
  margin-bottom: 15px;
  line-height: initial;
}

/* ChangeDeliveryAddress */

.ais_container-div-address {
  margin: 0px 20px 0px 20px;
  text-align: center
}

.ais_container-div-address.ais_web {
  text-align: center;
  margin: 20px 20px 0px 20px;
}

.ais_deliver-address-input {
  width: 100% !important;
  padding: 13px !important;
  margin-top: 15px !important;
  margin-bottom: 5px !important;
  border: solid 1px #e7e9ea;
  border-radius: 24px;
  font-size: 14px;
  color: #8589a1;
  line-height: initial;
}

.ais_deliver-address-input.ais_mismatch {
  margin-top: 0px !important;
  padding-left: 2px !important;
  padding-right: 2px !important;
  font-size: 16px !important;
}

.ais_device-select-button.enter-email-submit-button {
  width: 62%;
}

.ais_device-select-button {
  /* width: 32%; */
  width: 170px;
  padding: 11px;
  margin-top: 15px;
  border: solid 1px #e7e9ea;
  border-radius: 22px;
  background-color: #6EA32D;
  margin-bottom: 20px;
  color: #FFFFFF;
  font-size: 14px;
  line-height: initial;
}

.ais_device-select-button-custum {
  width: 100%;
  padding: 11px;
  margin-top: 10px;
  border: solid 1px #e7e9ea;
  border-radius: 22px;
  background-color: #c0c2c4;
  /* margin-bottom: 20px; */
  color: #1d3444;
  font-size: 14px;
  line-height: initial;
}


.ais_device-select-button-attach {
  width: 50%;
  padding: 13px;
  margin-top: 15px;
  border: solid 1px #e7e9ea;
  border-radius: 22px;
  background-color: #c0c2c4;
  margin-bottom: 20px;
  /* color: #1d3444; */
  font-size: 14px;
  line-height: initial;
}

.ais_device-btn-width {
  width: 46% !important;
  padding: 10px !important;
  height: 40px;

  /*  color: #1d3444 !important; */
}

#mobilenumber.ais_contact-number-input {
  width: 86%;
}

.margin0 {
  margin: 0px !important;
}

.margin-bottom10 {
  margin-bottom: 10px !important;
}
.serviceUnavailableBtnClass{
  display: flex;
  /* margin: 15px; */
}

.margin-bottom20 {
  margin-bottom: 20px !important;
}

.ais_device-select-button {}

.ais_device-select-button.ais_selected {
  background-color: #C0C2C4;
  color: #fff;
}

.ais_device-select-button.ais_proceed {
  margin-bottom: 0px;
  /* width: 35%; */
}

.ais_device-select-button.ais_survey-question {
  width: 200px !important;
}

.ais_device-select-button.ais_emi {
  margin-top: 0px;
}

.ais_device-select-button.ais_credit-card {
  margin-bottom: 0px;
  width: 32%;
  margin-right: 5px;
}

/* .ais_device-form-select-button {
    width:100%;
    padding:13px;
    margin-top: 15px;
    border: solid 1px #e7e9ea;
    border-radius: 5px 5px 5px 5px;
    background-color: #c0c2c4; */
/* background: linear-gradient(to right, #e32490 , #ed1a3d); */
/* color: #1d3444;
    font-family: Avenir-Medium;
    font-size: 14px;
    line-height: initial;
} */

.ais_device-select-button-submitted {
  width: 100%;
  padding: 13px;
  margin-top: 15px;
  border: solid 1px #e7e9ea;
  border-radius: 24px;
  background-color: #bfd22b;
  /* background: linear-gradient(to right, #e32490 , #ed1a3d); */
  margin-bottom: 20px;
  color: white;
  font-size: 14px;
  line-height: initial;
}

.ais_device-select-button-highlighted {
  background-color: #bfd22b;
  /* background: linear-gradient(to right, #e32490 , #ed1a3d); */
  color: white;
  font-size: 14px;
}

.ais_device-form-select-button:hover {
  background-color: #bfd22b;
  /* background: linear-gradient(to right, #e32490 , #ed1a3d); */
  color: white;
  font-size: 14px;
}

/* SubmitContactNumber */

.ais_contact-number-input {
  width: 100%;
  padding: 14px;
  margin-top: 15px;
  border: solid 1px #e7e9ea;
  background-color: #ffffff;
  border-radius: 22px;
}

.ais_contact-number-input-cus {
  width: 100%;
  padding: 14px;
  /* margin-top: 15px; */
  border: solid 1px #e7e9ea;
  background-color: #ffffff;
  border-radius: 22px;
}

.ais_current-mobile-device-label-custom {
  width: 100%;
  /* font-family: Helvetica-Bold; */
  font-size: 12px;
  color: #c0c2c4;
  text-align: left !important;
  margin-left: 10px;
  /* margin-bottom: 25px; */
  /* line-height: 2; */
  font-weight: 800;
}

/* ProcessServiceReq */
.ais_current-device-spec {
  font-size: 13px;
  color: #1d3444;
  text-align: center;
  font-weight: normal;
}

.ais_current-device-spec.ais_doc {
  margin-bottom: 30px;
}

.ais_current-device-spec.ais_upload {
  margin-bottom: 10px;
  margin-top: 10px;
  /* font-size: 14px; */
}

.ais_dashed-placeholder {
  border: 2px dashed #bfd22b;
  width: 100%;
  margin: 10px 0px 10px 0px;
  border-radius: 10px;
}

.ais_drop-div {
  line-height: 1;
  padding: 20px 0px;
}

/* .ais_files-container {
    border: 2px solid black;
    width: 100%;
    margin: 10px 0px 50px 0px;
} */

.ais_mandatory-astrix,
.ais_mandatory-astrix-cus {
  font-family: Helvetica-Bold;
  color: #547c22;
  font-size: 16px;
}

.ais_mandatory-astrix {
  font-size: 13px;
}

.ais_hotline-num {
  font-size: 20px;
  font-family: Helvetica-Bold;
  color: #547c22;
}

.ais_discount-wrapper {
  margin-bottom: 30px;
}

.ais_inline-element {
  display: inline-block;
  width: 100%;
}

.ais_inline-element-div {
  display: inline-block;
  text-align: left;
  line-height: 1;
  margin: 15px 0px 15px 0px;
  width: 40%;
}

.ais_font-weight {
  font-weight: normal;
}

.ais_head-width,
.ais_primary-head-width {
  width: 80%;
}

.ais_secondary-head-width {
  width: 90%;
}

.ais_primary-header {
  font-family: Helvetica;
  font-size: 16px;
  color: #1d3444;
  text-align: center;
  font-weight: 900;
  line-height: 18.75px;
  margin-bottom: 13px;
}

.ais_secondary-header {
  font-family: Helvetica;
  font-size: 14px;
  font-weight: normal;
  color: #1d3444;
  text-align: center;
  line-height: 16.1px;
  max-width: 250px;
  margin-bottom: 13px;
}

.ais_current--form-device-spec {
  font-size: 13px;
  color: black;
  text-align: center;
  font-weight: 200;
  font-family: Helvetica-Bold;
}

.ais_outer-align-button {
  width: 100%;
  text-align: center;
  margin-bottom: 25px;
}

.ais_outer-align-button.ais_survey-question {
  margin-bottom: 0px;
}

.ais_outer-align-button-top-bottom {
  width: 100%;
  text-align: center;
  margin: 25px 0 25px 0;
}

.ais_outer-align-button.ais_doc {
  margin-bottom: 0px !important;
}

.ais_outer-confirm-delivery-align-button {
  width: 100%;
  text-align: center;
  margin-bottom: 25px;
  margin-top: 20px;
}

/* DeviceDeliverAlert */

.ais_current-container-div {
  margin-left: 28px;
  margin-right: 28px;
}

.ais_display-script-container {
  margin: 28px;
}

.ais_current-device-img {
  background: url("../images/mobile/img-iphoneX-small.png") no-repeat;
  width: 30%;
  height: 90px;
  /*margin-left: 25px;*/
  float: left;
  background-color: #ffffff;
  background-position: right;
}

.ais_current-form-device-name-div {
  padding-bottom: 35px;
  padding-top: 25px;
  /*float: left;*/
  width: 90%;
  background-color: #ffffff;
}

.ais_current-form-device-desc {
  font-size: 12px;
  color: #c0c2c4;
  float: left;
  text-align: left;
}

.ais_current-form-device-desc.ais_dropMessage {
  font-size: 13px !important;
  font-weight: 200;
}

.ais_current-form-drop-Message-Input {
  font-size: 14px;
  color: #8589a1;
  width: 100%;
  background-color: #f5f5f5;
  border: solid 1px white;
  border-radius: 24px;
  padding: 13px;
  margin-top: 15px;
}

.ais_current-form-drop-Message-Input.ais_textarea {
  height: 75px;
  resize: none;
  line-height: 1;
  margin: 0;
}

.ais_current-form-drop-Message-Input.ais_textarea.ais_address {
  margin-top: 15px !important;
}

.ais_prefer-device-desc {
  font-size: 13px;
  color: #1d3444;
  text-align: left;
}

.ais_prefer-device-desc.ais_deviceOptions {
  height: 30px;
}

/* DeviceInput */
.ais_check-device-button {
  width: 100%;
  padding: 13px;
  margin-top: 15px;
  border: solid 1px #e7e9ea;
  border-radius: 5px 5px 5px 5px;
  background-color: #e7e9ea;
  color: #1d3444;
  font-size: 14px;
}

.ais_check-device-button:hover,
.ais_check-device-button-selected {
  width: 100%;
  padding: 13px;
  margin-top: 15px;
  border: solid 1px #e7e9ea;
  border-radius: 5px 5px 5px 5px;
  background: linear-gradient(to right, #e32490, #ed1a3d);
  color: white;
  font-size: 14px;
}

.ais_pincode_txt_content {
  text-align: center;
  font-size: 13px;
  font-weight: normal;
}

.ais_address_label {
  text-align: center;
  font-size: 13px;
  width: 50%
}

.ais_credit_card_label {
  text-align: center;
  font-size: 13px;
}

.ais_align_center {
  text-align: center !important;
  margin-left: 10px !important;
}

/* PincodeConfirmation */
.ais_align-button {
  width: 35%;
  padding: 13px;
  margin-top: 15px;
  margin-left: 10px;
  margin-right: 10px;
  border: solid 1px #e7e9ea;
  border-radius: 5px 5px 5px 5px;
  display: inline-block;
  background-color: #e7e9ea;
  color: #1d3444;
  font-size: 14px;
}

.ais_align-button:hover {
  width: 35%;
  padding: 13px;
  margin-top: 15px;
  margin-left: 10px;
  margin-right: 10px;
  border: solid 1px #e7e9ea;
  border-radius: 5px 5px 5px 5px;
  display: inline-block;
  background: linear-gradient(to right, #e32490, #ed1a3d);
  color: white;
  font-size: 14px;
}

/* currentDeviceConfirmation */
.ais_current-form-device-header-div {
  text-align: center;
  margin-top: 30px;
  margin-left: 20px;
  margin-right: 20px;
}

.ais_current-form-device-icn {
  /* margin-left: 25px; */
  display: inline-block;
  vertical-align: top;
}

.ais_current-form-selected-device-icn {
  /* margin-top: 20px; */
  margin: 20px 10px 20px 30px;
  float: left;
}

.ais_device-divider {
  width: 5%;
  height: auto;
  display: inline-block;
}

.ais_device-divider.ais_button {
  width: 2%;
}

.ais_current-popup-center-div {
  align-content: center;
  margin-left: 20px;
  margin-top: 10px;
  text-align: center !important;
}

/* .ais_current-your-device-name-div {
    padding-bottom: 35px;
    padding-top: 25px;
    width: 50%;
    background-color: #ffffff;
    margin-left: 10px;
    display: inline-block;
    vertical-align: bottom;
} */

.ais_cancel-header {
  text-align: center;
  font-size: 13px;
  font-weight: normal;
  color: #3d3d3d;
  font-family: Helvetica-Bold;
  margin-bottom: 15px;
}

.ais_current-form-your-device-name-div {
  padding-bottom: 35px;
  padding-top: 10px;
  width: 50%;
  background-color: #ffffff;
  margin-left: 10px;
  display: inline-block;
}

.ais_align-popup-button {
  width: 40%;
  padding: 10px 20px;
  border: solid 1px #e7e9ea;
  border-radius: 24px;
  display: inline-block;
  background-color: #6EA32D;
  color: #FFFFFF;
  font-size: 14px;
}
.width-auto{
  width: auto;
}

.ais_align-popup-button.selectNew {
  width: 30%;
  margin-top: 20px;
}

.ais_align-popup-button-alt {
  width: 45%;
  padding: 13px;
  border: solid 1px #e7e9ea;
  border-radius: 24px;
  display: inline-block;
  background-color: #e7e9ea;
  color: #1d3444;
  font-size: 14px;
}

.ais_align-popup-button.ais_hold {
  width: 50% !important;
}

.ais_align-popup-button-selected {
  width: 40%;
  padding: 10px 20px;
  border: solid 1px #e7e9ea;
  border-radius: 24px;
  display: inline-block;
  background-color: #C0C2C4;
  color: white;
  font-size: 14px;
}

.ais_align-popup-button-selected-alt {
  width: 45%;
  padding: 13px;
  border: solid 1px #e7e9ea;
  border-radius: 24px;
  display: inline-block;
  background-color: #bfd22b;
  color: white;
  font-size: 14px;
}

.ais_align-popup-nosr {
  width: 55%;
  padding: 13px;
  border: solid 1px #e7e9ea;
  border-radius: 24px;
  display: inline-block;
  background-color: #e7e9ea;
  color: #1d3444;
  font-size: 14px;
  margin-bottom: 10px;
}

.ais_align-popup-nosr-selected {
  width: 55%;
  padding: 13px;
  border: solid 1px #e7e9ea;
  border-radius: 24px;
  display: inline-block;
  background-color: #bfd22b;
  color: white;
  font-size: 14px;
  margin-bottom: 10px;
}

.ais_align-popup-button-resume {
  width: 40%;
  padding: 13px;
  border: solid 1px #e7e9ea;
  border-radius: 24px;
  display: inline-block;
  background-color: #e7e9ea;
  color: #1d3444;
  font-size: 12px;
}

.ais_align-popup-button-selected-resume {
  width: 40%;
  padding: 13px;
  border: solid 1px #e7e9ea;
  border-radius: 24px;
  display: inline-block;
  background-color: #C0C2C4;
  color: white;
  font-size: 12px;
}

.ais_align-popup-button-selected.ais_hold {
  width: 50% !important;
}

.ais_align-endChat-button,
.ais_align-endChat-button:hover {
  width: 40%;
  padding: 13px;
  border: solid 1px #e7e9ea;
  border-radius: 24px;
  display: inline-block;
  background-color: #e7e9ea;
  color: #bfd22b !important;
  font-size: 14px;
  text-decoration: none;
}

.ais_align-endChat-button-selected {
  width: 40%;
  padding: 13px;
  border: 0;
  border-radius: 24px;
  display: inline-block;
  background-color: #bfd22b !important;
  /* linear-gradient(to right, #e32490 , #ed1a3d); */
  color: white;
  font-size: 14px;
}

.ais_visible-mobile {
  display: inline !important;
  margin: 5px;
}

.ais_hidden-mobile {
  display: none !important;
}

.ais_align-popup-button-2{
  width: 40%;
  padding: 10px 20px;
  border: solid 1px #6EA32D;
  border-radius: 24px;
  display: inline-block;
  background-color: white;
  color: #6EA32D;
  font-size: 14px;
}
/*
.ais_device-options-header
{
    width: 80%;
    font-size: 14px;
    font-weight: 900;
} */
.ais_header-width {
  width: 80%;
}

.dropdown {
  
}

.dropdown > button{
  color: #8589A1 !important;
  background-color: white !important;
  padding: 0 !important;
  border: 0px;
  box-shadow: unset !important;
}

.dropdown-item.active{
  background-color: #bfd22b;
}

.dropdown-menu {
  height: 170px;
  font-size: 14px;
  font-weight: normal;
  overflow-y: scroll;
  width: 100%;
  overflow-x: hidden;
}

.dropdown-menu>li {
  height: 42px;
  display: table;
  width: 100%;
}

.dropdown-menu>li>a {
  color: #1d3444;
  text-decoration: none;
  border-radius: 4px;
  background-color: #ffffff;
  /* border: solid 1px #e7e9ea; */
  outline: 0;
  height: 100%;
  padding: 5px;
  white-space: initial;
  font-size: 14px;
  display: table-cell;
  vertical-align: middle;
}

.dropdown-menu>li>a:focus,
.dropdown-menu>li>a:hover {
  color: #fff;
  text-decoration: none;
  background-color: #bfd22b;
  /* border: solid 1px #e7e9ea; */
  border-radius: 4px;
  outline: 0;
  height: 100%;
  padding: 5px;
  white-space: initial;
  font-size: 14px;
  display: table-cell;
  vertical-align: middle;
}

#dropdown-size-medium.device-mismatch-dropdown,
.device-mismatch-dropdown+.dropdown-menu>li>a,
.device-mismatch-dropdown+.dropdown-menu>li>a:focus,
.device-mismatch-dropdown+.dropdown-menu>li>a:hover {
  font-size: 12px;
}


#dropdown-size-large+.dropdown-menu {
  height: 306px;
}

.dropdown-toggle {
  width: 100%;
  max-height: 44px;
  text-align: left;
  height: 100%;
  border-radius: 4px;
  font-size: 14px;
  font-weight: normal;
}

/* .ais_dropdown-menu{
    height: 100px !important;
} */

.dropdown-toggle.ais_btn-default.focus,
.dropdown-toggle.ais_btn-default:focus,
.dropdown-toggle.ais_btn-default:hover {
  background-color: #fff;
}

.btn-default.ais_active.focus,
.btn-default.ais_active:focus,
.btn-default.ais_active:hover,
.btn-default:active.ais_focus,
.btn-default:active:focus,
.btn-default:active:hover,
.open>.ais_dropdown-toggle.ais_btn-default.focus,
.open>.ais_dropdown-toggle.ais_btn-default:focus,
.open>.ais_dropdown-toggle.ais_btn-default:hover {
  color: #1d3444;
  background-color: #fff;
  border-color: #bfd22b;
}

.caret {
  float: right;
  margin-top: 5px;
}

.ais_margintop {
  margin-top: 10px;
}

.ais_error-div {
  margin-top: 4px;
  font-weight: normal;
  color: #ed1a3d;
  font-size: 12px;
}

.ais_device-select-button.ais_turn-off-fmip {
  margin-bottom: 0px;
}

.ais_current-form-device-div.ais_turn-off-fmip .ais_header-txt-div>ol {
  text-align: left;

}

.ais_header-txt-div.ais_turn-off-fmip {
  margin-left: 8px;
  margin-right: 8px;
}

.ais_error-div.ais_imei {
  text-align: left;
  padding-left: 10px;
}

.ais_file-icon {
  height: 35px;
  width: 30px;
}

.ais_cancel-icon {
  width: 20px;
  height: 20px;
}

/* .ais_error-div.ais_address {
    font-family: Avenir-Book;
} */

.ais_placeholder-font {
  font-size: 13.5px;
}

.ais_cancel-request {
  font-size: 17px;
  /* color: #1d3444; */
  text-align: center;
  font-weight: 900;
}

.ais_note-div {
  font-size: 12px;
  text-align: left;
  line-height: 1.5;
  margin-bottom: 10px;
  margin-top: 10px;
}

/* .ais_device-select-button.ais_checkDevice{
    width: 100%;
    margin-bottom: 0px;
} */

.ais_device-select-button.ais_checkDevice.ais_selected {
  width: 100%;
  margin-bottom: 0px;
}

.ais_device-select-button-custum.ais_checkDevice.ais_selected {
  width: 100%;
  margin-bottom: 0px;
  background-color: #bfd22b !important;
  color: white;
}

.ais_device-select-button.disclaimerButtons {
  /* width: 32%; */
  margin-bottom: 0px;

}

.ais_app-header-cancel {
  width: auto;
  /* margin: 30px 0px 0px 15px; */
  font-size: 14px;
  font-weight: 700;
  text-align: right;
  color: #3d3d3d;
  float: right;
  background-color: #c0c2c4;
  /* padding: 5px 25px 5px 25px; */
  border: solid 1px #e7e9ea;
  border-radius: 22px;
  margin: 0px 30px 0px 4px;
}

.ais_app-header-cancel.active {
  background-color: #bfd22b;
  color: #fff;
}

.ais_margin-bottom {
  margin-bottom: 10px;
}

.ais_app-header-time {
  font-size: 12px;
  color: #3d3d3d;
}
.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  margin: 0.4rem !important;
}
@media(min-width: 480px) {
.wbkoThankYouImg{
  margin-top: 30px;
  margin-bottom: 10px;
}
 .mobile_img {
   display: none;
 }
 .desktop_img {
   display: block;
 }
 .OuterData {
  position: relative;
  z-index: 10;
 }

 .imageData {
   position: fixed;
   bottom: 0;
 }
}

.logo-lg{
  display: none;
}

.logo-lg > img{
  width: 140px;
}

.logo-sm{
  display: block;
}

.logo-sm > img{
  width: 60px;
}
.logo-lg-churn{
  display: none;
}
.logo-sm-churn{
  display: block;
}


@media (min-width: 991px) {
  .ais_current-device-enquiryBox{
    margin: 0px;
  }
  .ais_sent-message {
    max-width: 85%;
  }
  .ais_ui-wrapper_reason {
    margin-left: 131px;
  }
  .ais-label{
    font-size: 31px;
    line-height: 26px;
  }
 .mobile_img {
   display: none;
 }
 .desktop_img{
  display: block;
 }
 .image_pay_only {
   width: auto;
 }
 .OuterData{
   position: relative;
   z-index: 10;
 }
 .imageData{
  position: fixed;
  bottom: 0;
 }
 .thankyou-label {
  line-height: 28px;
  padding: 20px;
  margin-bottom: 0px;
  margin-top: 0px;
 }
 .thankyou-text {
   margin-top: 0px;
 }
 .margin124{
  margin-top: 0px;
 }
.icon {
    left: 490px;
  }

  .background {
    max-width: 160px;
  }

  .background-image {
    max-width: 200px;
  }

  .icon-image {
    margin-top: 13px;
    left: 417px;
  }

  .reverse-margin {
    left: 392px;
  }
  .reasonsWrapper{
    max-width: 100%;
    justify-content: center;
  }
  .reasons {
    padding: 15px;
    justify-content: center;
  }
  .json-text {
    font-size: 14px;
    margin-top: 40px;
    margin-bottom: 20px;
  }
  .width-full {
    width: 100% !important;
  }
  .ThankYouContent{
    padding-top: 15px;
  }
  .ais_device-select-button.ais_emi {
    width: 48% !important;
    display: inline-block;
  }

  .ais_busy_all {
    width: 48% !important;
  }

  .ais_margin-bottom {
    margin-bottom: 10px;
  }

  .ais_deliver-address-input.ais_mis {
    padding-left: 2px !important;
    padding-right: 2px !important;
    font-size: 16px !important;
  }

  .ais_app-header-sr {
    margin-top: 10px;
    font-size: 14px;
    font-weight: 700;
    color: #3d3d3d;
  }

  .inline_ele {
    display: inline-block;
  }

  .ais_app-header-cancel {
    width: auto;
    margin: 30px 0px 0px 15px;
    font-size: 14px;
    font-weight: 700;
    text-align: right;
    color: #3d3d3d;
    float: right;
    background-color: #c0c2c4;
    padding: 5px 25px 5px 25px;
    border: solid 1px #e7e9ea;
    border-radius: 22px;
  }

  .ais_app-header-cancel.active {
    background-color: #bfd22b;
    color: #fff;
  }

  .ais_current-terms-div {
    border: solid 1px #e7e9ea;
    border-radius: 16px 16px 16px 16px;
    width: 600px;
    overflow: auto;
    background-color: #ffffff;
    margin: auto;
  }

  .ais_current-terms-div-cus {
    border: solid 1px #e7e9ea;
    border-radius: 16px 16px 16px 16px;
    width: 548px;
    overflow: auto;
    background-color: #ffffff;
    margin: 16px auto;
  }


  .ais_current-form-device-div.ais_features {
    width: 280px !important;
    margin-top: 30px;
  }

  .ais_inline-element {
    /*     display: inline-block;
    width: 70% !important; */
  }

  .ais_deliver-address-input.ais_mis {
    padding: 13px !important;
    font-size: 14px !important;
  }

  .ais_deliver-address-input.ais_mismatch {
    margin-top: 0px !important;
    padding: 13px !important;
    font-size: 14px !important;
  }

  .ais_header-txt-div.ais_turn-off-fmip {
    margin-bottom: 25px !important;
  }

  .ais_current-form-device-div.ais_display-script {
    width: 600px !important;
    line-height: 1.5;
    /* font-size: 16px !important; */
  }

  .ais_current-form-device-div.ais_display-script.ais_proceed {
    width: 300px !important;
    text-align: center;
  }

  .ais_current-form-device-div.ais_turn-off-fmip {
    width: 350px !important;
  }

  .ais_scrolling-wrapper::-webkit-scrollbar {
    display: block;
  }

  .ais_scrolling-wrapper::-webkit-scrollbar {
    width: 5px;
    background-color: #cccccc;
  }

  .ais_scrolling-wrapper::-webkit-scrollbar-thumb {
    background-color: #6d6d6d;
    border-radius: 0px;
  }

  .ais_scrolling-wrapper::-webkit-scrollbar-thumb:hover {
    background-color: #6d6d6d;
    border: 1px solid #333333;
  }

  .ais_scrolling-wrapper::-webkit-scrollbar-thumb:active {
    background-color: #6d6d6d;
    border: 1px solid #333333;
  }

  .ais_scrolling-wrapper::-webkit-scrollbar-track {
    -webkit-box-shadow: 0 0 1px gray inset;
  }

  .ais_current-popup-center-div {
    text-align: center;
  }

  .ais_visible-mobile {
    display: none !important;
  }

  .ais_hidden-mobile {
    display: inline !important;
    margin: 5px;
  }

  .ais_device-select-button.ais_term {
    width: 50%;
  }

  .ais_device-select-button.ais_proceed {
    width: 70%;
  }

  .ais_device-select-button.ais_selected {
    /* width: 100%; */
  }

  .ais_current-form-device-div.ais_proceed {
    width: 400px !important;
  }



  /* .ais_current-form-device-div.ais_warranty-disclaimer {
        width: 500px !important;
    } */

  .ais_current-terms-div.ais_warranty-disclaimer .ais_buttons-wrapper {
    text-align: center;
  }

  .ais_current-terms-div.ais_warranty-disclaimer .ais_buttons-wrapper>.ais_device-select-button {
    width: 45%;
    margin-right: 10px;
  }

  .ais_current-form-device-div.ais_proceed {
    width: 360px !important;
  }

  .ais_current-form-device-div.FAQ_page {
    width: 670px !important;
  }

  .ais_current-form-device-div.ExCust {
    width: 280px !important;
  }

  .ais_current-form-device-div.ais_proceed.COD {
    width: 415px !important;
  }

  .ais_current-form-device-div.ais_proceed_alt {
    width: 400px !important;
  }

  .ais_current-form-device-div.ais_turn-off-fmip .ais_header-txt-div>ol {
    text-align: left;
    padding-left: 20px;
  }

  .ais_confirm-details-div {
    width: 500px !important;
  }

  /* .ais_other-details-desc {
    font-size: 15px;
  } */
  /*  .ais_confirm-details-blue-label {
    font-size: 13px;
  } */

  /* .ais_swop-device-name {
    font-size: 14px;
  } */

  .ais_confirm-details-button {
    width: 50%;
    margin-left: 120px;
    margin-right: 20px;
  }

  .ais_confirm-details-button-highlighted {
    width: 50%;
    border: solid 1px #e7e9ea;
    border-radius: 24px;
    background-color: #bfd22b;
    color: white;
    font-size: 14px;
    margin-left: 120px;
    margin-right: 20px;
  }

  /* .ais_confirm-details-button:hover {
    width: 75%;
    margin-left: 50px;
    margin-right: 50px;
  } */

  .ais_prefer-device-desc.ais_emi {
    display: inline-block;
    width: 60%;
  }

  .ais_confirm-delivery-icn.ais_emi {
    display: inline-block;
    width: 30%;
  }

  .ais_current-form-emi-div {
    width: 64%;
    float: right;
  }

  .ais_device-divider.ais_emi {
    width: 3%;
    height: auto;
    display: inline-block;
  }

  .ais_deliver-address-input.ais_emi {
    width: 48% !important;
    display: inline-block;
  }

  .ais_deliver-address-input.ais_emi.ais_active {
    color: #1d3444;
  }


  .ais_device-outer.ais_deliveryAddress {
    width: 600px !important;
    line-height: 29px;
    clear: both;
  }

  .ais_device-outer.ais_deliveryAddress:after {
    bottom: 265px;
  }

  .ais_device-outer.ais_deliverySchedule {
    line-height: 50px;
    width: 360px !important;
    text-align: center;
  }

  .ais_change-delivery-datePicker-input {
    width: 318px !important;
  }

  .ais_container-div-address.ais_deliverySchedule {
    margin: 0px 20px 0px 20px;
    text-align: center;
  }

  .ais_container-div-address.ais_web.ais_doc {
    text-align: center;
  }

  .ais_device-outer.ais_web.ais_doc {
    line-height: 50px;
    width: 600px !important;
  }

  .ais_device-outer.ais_web.ais_doc::after {
    background: url("./../images/ais-images/icon-upload.png") no-repeat center !important;
    background-size: 50% !important;
  }


  .ais_confirm-delivery-icn {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .ais_file-icon {
    height: 35px;
    width: 30px;
  }

  .ais_cancel-icon {
    width: 20px;
    height: 20px;
  }

  .ais_wrapper {
    display: inline-block;
    margin: auto;
    width: 100%;
    text-align: center;
  }

  .ais_wrapper.ais_grid-options {
    display: block;
    margin: 0 auto;
    width: 70%;
  }

  .ais_current-form-device-div.ais_enquiryBox {
    width: 150px !important;
    margin: initial;
    display: inline-block;
    height: 110px;
    margin: 3px 10px;
    overflow: hidden;
    cursor: pointer;
  }



  .ais_enquiryBox div img {
    padding-bottom: 5px;
  }

  .react-datepicker__month-container {
    top: initial !important;
    /* width: 400px !important; */
  }

  .react-datepicker-popper {
    z-index: 5;
    /*top: unset !important;*/
    transform: translate3d(0px, 0px, 0px) !important;
  }
  .react-datepicker__day {
    font-size: 12px !important;
    font-family: Avenir-Medium !important;
    color: #1d3444;
    width: 1.5rem !important;
    line-height: 1.0rem !important;
  }
/*
  .react-datepicker {
    top: 42px !important;
    font-size: 1rem !important;
    border: solid 1px #e7e9ea !important;
    border-bottom: solid 1px white !important;
  }

  .react-datepicker__triangle {
    display: none !important;
  }

  .react-datepicker__month-container {
    top: initial !important;
    width: 315px !important;
  }

  .react-datepicker__month {
    margin: 0px !important;
  }

  .react-datepicker__navigation--next {
    background: url("../images/icons/ic-chevron_right.png") no-repeat !important;
    width: 14px !important;
    height: 14px;
    border: none !important;
    top: 13px !important;
  }

  .react-datepicker__navigation--previous {
    background: url("../images/icons/ic-chevron_left.png") no-repeat !important;
    width: 14px !important;
    height: 14px;
    border: none !important;
    top: 13px !important;
  }

  .react-datepicker__header {
    padding-top: 0px !important;
    background: linear-gradient(to right, #e32490, #ed1a3d);
    border-bottom: none !important;
  }

  .react-datepicker__current-month {
    height: 45px;
    padding: 10px;
    font-size: 16px !important;
    color: white !important;
    font-weight: normal !important;
  }

  .react-datepicker__day-names {
    height: 40px;
    vertical-align: middle;
    background: white;
    border-bottom: none !important;
  }

  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    margin: 1.166rem !important;
  }

  .react-datepicker__day-name {
    font-size: 12px !important;
  }

  .react-datepicker__day {
    font-size: 12px !important;
    color: #1d3444 !important;
    width: 1.9rem !important;
    line-height: 1.9rem !important;
  }

  .react-datepicker__day--selected {
    background-color: #00b9e3 !important;
    color: white !important;
    border-radius: 0.9rem !important;
  }

  .react-datepicker__day--selected:hover {
    background-color: #00b9e3 !important;
    color: white !important;
  }

  .react-datepicker__week {
    border-bottom: solid 1px #e7e9ea;
  }
*/

  .logo-lg{
    display: block;
  }

  .logo-sm{
    display: none;
  }
  
.logo-lg-churn{
  display: block;
}
.logo-sm-churn{
  display: none;
}

}
  .react-datepicker__day--disabled {
    color: #8589a1 !important;
  }

.ais_current-form-device-div.ais_enquiryBox-selected {
  width: 150px;
  margin: initial;
  display: inline-block;
  height: 110px;
  margin: 3px 10px;
  overflow: hidden;
  cursor: pointer;
  /* background-color: #bfd22b; */
  color: white;
  /* border-color: #bfd22b; */
  border: 1.5px solid #BFD22B;
}

/* inline buttons payment */

.ais_btn-div {
  display: inline-table;
  width: 117px;
  /* height: 75px; */
  border-radius: 10px;
  background-color: #ffffff;
  border: solid 1px #000000;
  /* margin: 29px 6px; */
  margin-top: 10px;
  /* margin-left:5px; */
  margin-right: 6px;
  margin-bottom: 28px;
  text-align: center;
  cursor: pointer;
}

.ais_btn-div.ais_selected {
  background-color: #bfd22b;
  border: none;
}

.ais_image-icon-div {
  padding-left: 37px;
  padding-right: 40px;
  padding-top: 14px;
  padding-bottom: 15px;
}

.ais_btn-text {
  height: 40px !important;
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: black;
}

.ais_go_back {
  color: #bfd22b;
  font-size: 13px;
  height: 20px;
  text-align: left;
  border: none;
  background: white;
  font-weight: 700;
  margin: 5px;
  cursor: pointer;
  display: inline-block;
}

.ais_btn-div.ais_selected .ais_btn-text {
  color: #ffffff;
}

/* end of payment buttons */

/* New chat input css*/
.ais_ui-wrapper {
  /* padding: 10px; */
  margin-left: 131px;
  background-color: #f5f5f5;
  border-radius: 40px;
  border: solid 1.5px #f5f5f5;
}
.wrapper-otherReason{
  position: relative;
}
.ais_ui_button {
  /* background-image: url( '../images/ais-images/button-send-normal.png' );
  background-repeat: no-repeat; */
  float: right;
  height: 50px;
  width: 50px;
  background-color: #f5f5f5;
  border-radius: 50%;
  border: none;
}
.other_reason_btn{
  position: absolute;
  right: 18px;
  top: 0px;
}
.other_reason_btn img{
  margin: auto;
}
.ais_ui_input {
  width: 100%;
  height: 50px;
  border: none;
  border-radius: 27.5px;
  background-color: #f5f5f5;
  padding-left: 15px;
}

.ais_ui_inputwrapper {
  overflow: hidden;
  padding-right: 1.5em;
}

.ais_padding-bottom-5 {
  padding-bottom: 15px;
}

/*credit card responsive css*/
.ais_padding-right-5 {
  padding-right: 5px;
}

.ais_padding-left-5 {
  padding-left: 5px;
}

.ais_cc-input-field {
  width: 100%;
  padding: 12px;
  border-radius: 22px;
  border: solid 1px #e7e9ea;
  font-size: 15px;
  color: #3d3d3d;
  -webkit-box-shadow: none;
  box-shadow: none;
  height: 40px;
}

.ais_cc-text-error {
  color: #ED1A3D;
  font-size: 12px;
  height: 10px;
}

.form-row {
  line-height: 22.8px;
}

.row {
  margin-right: 0px;
  margin-left: 0px;
}

/*Progres bar css*/
.ais_progress-bar-wrapper {
  width: 500px;
  /* display: inline-block;
  position: fixed;
  top: 590px;
  left: 300px;
  height: 4px; */
  font-size: 14px;
  text-align: center;
  padding-top: 20px;
  margin: 0px auto;
}

/* delivery address change*/
.ais_address-input-field {
  width: 100%;
  padding: 7px;
  border-radius: 22px;
  border: solid 1px #cccccc;
  font-size: 15px;
  color: #3d3d3d;
  box-shadow: none;
  height: 40px;
}

.ais_select-field {
  width: 100%;
  padding: 7px;
  height: 40px !important;
  border-radius: 22px;
  border: solid 1px #cccccc;
  font-size: 15px;
  border-color: #cccccc;
  color: #000000;
  background: transparent;
}

.ais_textbox-label {
  font-family: Helvetica-Bold;
  font-size: 14px;
  color: #3d3d3d;
  text-align: center;
  /* line-height: 2; */
  font-weight: 600;
  float: left;
}

.d-flex {
  display: flex;
}

.d-flex > div:first-child{
  display: block;
  padding: 15px;
}


.loader-wrap {
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
}
.loader {
  border: 16px solid #bfd22b; /* Light grey */
  border-top: 16px solid #6ea32d; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.holds-the-iframe {
  background:url(../images/ais-images/preloader.gif) center center no-repeat;
}
.rangeslider__labels .rangeslider__label-item {
  width: 22px;
}
.ais_app_header_div {
  justify-content: flex-end;
}
.agent-chatr-thanks {
  color: #3D3D3D;
  text-align: center;
  width: 400px;
  margin: 20px auto 0;
  font-family: "HelvethaicaAIS";
}
.agent-chatr-thanks h4{
  font-size: 32px;
  margin: 0 0 40px 0;
  line-height: 30px;
}
.agent-chatr-thanks p{
  font-size: 24px;
  line-height: 24px;
}
.sub-title{
font-size: 15px;
line-height: 18px;
color: #333333;
}

@media (max-width: 767px) {
  .agent-chatr-thanks{width: 100%;}
  .ais_fmip-instructions-div {
    width: 300px;
    height: 560px;
  }

  #dropdown-size-medium.device-mismatch-dropdown,
  .device-mismatch-dropdown+.dropdown-menu>li>a,
  .device-mismatch-dropdown+.dropdown-menu>li>a:focus,
  .device-mismatch-dropdown+.dropdown-menu>li>a:hover {
    font-size: 12px;
  }

  .ais_fmip-inst-wrapper .ais_fmip-instruction {
    width: 100%;
  }
  .devicelist-slide:not(:only-child) > div,
  .alternatedevice-slide:not(:only-child) > div {
      width: calc(100vw - 75px);
  }
  .devicelist-slide:only-child > div,
  .alternatedevice-slide:only-child > div {
      width: calc(100vw - 8rem);
      min-width: 300px !important;
  }
}

@media screen and (min-width: 768px) {
  .horizontal-slider > div:only-child{
    margin-right: 0px;
  }

  .horizontal-slider > div:not(:only-child){
    margin-right: 10px;
  }

  .horizontal-slider > article > div {
      width: 320px;
  }
  .fmip-slide > div {
      max-width: 270px
  }
}

.text-center{
  text-align: center !important;
}

.enrolled-device-info-text{
  margin-top: 13px;
  display: flex;
  justify-content: center;
}

.enrolled-device-info-text > p{
  max-width: 315px;
  margin: 0px;
}

.enrolled-device-inquiry{
  border: solid 1px #e7e9ea;
  border-radius: 16px;
  overflow: auto;
  margin: 10px auto 0;
  line-height: initial;
  overflow-x: hidden;
  white-space: pre-wrap;
  max-width: 620px;
  padding: 22px 18px;
  border-radius: 10px;
}

.enrolled-device-inquiry > div{
  text-align: center;
}

.enrolled-device-items{
  margin-top: 23px;
  display: flex;
  flex-wrap: wrap;
}

.enrolled-device-items > div{
  padding-bottom: 8px;
  width: 100%;
}

.enrolled-input{
  height: 40px;
  width: 100%;
  border-radius: 20px;
  padding: 0px 15px;
  color: #8589A1;
  background: #FFFFFF;
  border: 1px solid #E7E9EA;
  box-sizing: border-box;
}

.enrolled-device-documents{
  display: flex;
  flex-direction: column;
  max-width: 350px;
  margin: auto;
}

.enrolled-device-documents >p{
  display: none;
  margin: 18px 0px 0px 0px;
  text-align: left;
}

.enrolled-device-notes{
  font-family: Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: center;
}

.enrolled-device-notes > p{ 
  margin:0px
}

.enrolled-device-notes >p:nth-of-type(3){
  font-style: italic;
}

.enrolled-dropzone{
  min-height: 70px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  color: #6EA32D;
}

.enrolled-dropzone > div{
  display: flex;
}

.enrolled-dropzone > div > span{
  padding-left: 13px;
}

.enrolled-loading-progress{
  margin-top: 7px;
  height: 4px;
  width: 188px;
  border-radius: 2.2354607582092285px;
  background: #BFD22B;
}

.enrolled-selected-files{
  display: flex;
  justify-content: center;
  color: #333333;
  font-family: Helvetica;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0px;
  text-align: left;
}

.enrolled-selected-files > div:nth-of-type(2){
  margin-left: 8px;
}

.enrolled-files-container{
  padding: 4px;
}

.enrolled-files-container > div{
  margin: 4px 0px;
}

.enrolled-device-buttons{
  margin-top: 23px;
}

.enrolled-device-buttons > button:nth-of-type(1){
  min-width: 145px;
  padding: 13px;
  border: solid 1px #6ea32d;
  border-radius: 24px;
  display: inline-block;
  background-color: transparent;
  color: #6EA32D;
  font-size: 12px;
}

.enrolled-device-buttons > button:nth-of-type(2){
  min-width: 145px;
  padding: 13px;
  border: solid 1px #6ea32d;
  border-radius: 24px;
  display: inline-block;
  background-color: #6EA32D;
  color: white;
  font-size: 12px;
}

.enrolled-form-disable{
  pointer-events: none;
}

@media only screen and (min-width: 768px) {
  .enrolled-device-items > div:nth-child(odd){
    padding-right: 5px;
    padding-bottom: 8px;
  }

  .enrolled-input{
    width: 285px;
  }

  .enrolled-device-items > div{
    width: unset;
  }

  .enrolled-device-documents >p{
    display: unset;
  }
}

input:disabled{
  background-color: #EFEFEF;
  opacity: 0.5;
}

.selected-asset{
  background-color: #C0C2C4;
  color: #FFFFFF;
}
.non-selected-asset{
  background-color: #E7E9EA;
  color: #C0C2C4;
}

.reason-wrapper{
  width: 600px;
  margin: 20px auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.reason-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  margin-top: 10px;
  width: 150px;
  height: 150px;
  box-shadow: 4px 4px 20px 0px #50505040;
  background: #FFFFFF;
  box-shadow: 4px 4px 20px rgba(80, 80, 80, 0.25);
  border-radius: 20px;
}

.reason-inner-selected{
  border: 2px solid #BFD22B
}

.reason-items > div:last-child{
  margin-top: 10px;
}
.reason-items > div > img{
  height: 60px;
  width: 60px;
}

@media only screen and (max-width: 768px) {
  .reason-wrapper{
    flex-wrap: nowrap;
    overflow-x: auto;
    justify-content: unset;
    width: 100%;
    padding: 10px;
    margin-bottom: -17px;
  }

  .reason-inner{
    min-width: 150px;
    max-width: 150px;
  }
}

.disabled-btn{
  background-color: #C0C2C4 !important;
  color: #FFFFFF !important;
  border: none !important;
}

.enrollment-input-error{
  text-align: left;
  padding: 4px 15px;
  color: red;
}
.enrolled-input-lg{
  display: none;
}
.enrolled-dropzone-lg{
  display: none;
}
.enrolled-dropzone-sm{
  display: flex;
}
.enrolled-input-sm{
  display: block;
}
.enrollment-reason-icon{
 display: flex;
 justify-content: center;
 align-items: center; 
}
@media only screen and (min-width: 728px) {
  .enrolled-input-lg{
    display: block;
  }
  .enrolled-input-sm{
    display: none;
  }
  .enrolled-dropzone-lg{
    display: flex;
  }
  .enrolled-dropzone-sm{
    display: none;
  }
}

.react-datepicker__day.react-datepicker__day {
  font-weight: bold;
}
.react-datepicker__day--selected.react-datepicker__day--selected {
  background:rgba(110, 163, 45, 0.5);
}
.react-datepicker__day--disabled.react-datepicker__day--disabled {
  opacity: 0.5;
  font-weight: normal;
}

.ais-checkbox {
  position: relative;
  width: 24px;
  height: 24px;
  border: solid 1px gray;
  border-radius: 3px;
}

.ais-checkbox.disabled {
  cursor: not-allowed;
  pointer-events: none;
}

.ais-checkbox.selected {
  background-color: #6EA32D
}

.ais-checkbox.selected:after {
  position: absolute;
  top: 0px;
  left: 5px;
  display: inline-block;
  content: '\2713';
  font-size: 16px;
  color: white;
}

#paymentUI {
  font-size: 16px !important;
}

#paymentUI div,
#paymentUI input,
#paymentUI span,
#paymentUI button,
#paymentUI label {
  font-size: 1em;
  line-height: 1.1;
}

#paymentUI label {
  gap: 1em;
}

#paymentUI label span:has(svg) {
  width: 1em;
  height: 1em; 
}

#paymentUI button[id*="button-submit"] {
  padding: 0.75em 1.75em;
  height: 3em;
}

#paymentUI div[class*="TextFieldInput"],
#paymentUI div[fieldstatus*="default"] {
  height: 3.5em
}

#paymentUI input {
  padding: 1.75em 0px 0.75em 0.75em;
}

#paymentUI form > div,
#paymentUI form > div > div {
  height: 3.5em
}